import { CalendarSize } from '../types'

export const getBoxSize = (size: CalendarSize) => {
  const SIZES = {
    S: {
      boxHeight: 40,
      boxWidth: 60,
    },
    M: {
      boxHeight: 40,
      boxWidth: 100,
    },
    L: {
      boxHeight: 40,
      boxWidth: 200,
    },
  }

  return SIZES[size]
}
