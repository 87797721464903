import styled from 'styled-components'

export const Boxes = styled.div<{ height: number; width: number }>`
  display: flex;
  flex-direction: column;
  .hovered_box:hover {
    background-color: #eef3f5 !important;
  }

  .box {
    position: relative;
    height: ${props => props.height}px;
    width: ${props => props.width}px;
    margin: 2.5px 0;
    border-radius: 4px;
    background-color: white;
    box-shadow: 0px 0px 13px 0px rgba(236, 236, 241, 0.84);
    cursor: grabbing;

    // &:hover {
    //   background-color: #d2e4ed;
    // }
    & .box__background {
      position: absolute;
      top: 0;
      height: 0;
      width: 100%;
    }
  }

  @media (max-width: 500px) {
    .box {
      width: 20px;
      height: 20px;
    }
  }
`
