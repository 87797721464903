import { AssignToOption } from 'hooks/useEventAssignToOptions'
// import { Autocomplete } from '@material-ui/lab'
import {
  AssagneeSection,
  Date,
  DateSection,
  Label,
  TimeSection,
  Type,
  Wrapper,
} from './Job.style'
import { Error } from 'types/Error'
import { IEventSettings } from 'features/Settings/Dispatch/components/EventsTab/EventsTab'
import { isOwner } from 'store/Orcatec/selectors/user'
import { Job as JobType } from 'types/Jobs'
// import { KeyboardDatePicker } from '@material-ui/pickers'
// import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import { selectDispatchSettings } from 'features/Dispatch/dispatchSlice'
import { useAppSelector } from 'store/Orcatec/hooks'
import AppointmentTypes from 'containers/MainContent/Orcatec/CreateAppointmentV2/AppointmentForm/components/AppointmentTypes'
import CustomTimePicker from 'containers/MainContent/Orcatec/components/CustomTimePicker'
// import InputField from 'containers/MainContent/Orcatec/components/Input'
import moment from 'moment-timezone'
import Notes from 'containers/MainContent/Orcatec/components/Notes'
import useTechnicians from 'hooks/useTechnicians'
import { AppointmentTimezone } from 'containers/MainContent/Orcatec/CreateAppointmentV2/AppointmentForm/components/AppointmentTimezone/AppointmentTimezone'
import {
  // selectCompany,
  selectJobDictionary,
} from 'store/Orcatec/selectors/company'

import DatePicker from 'react-multi-date-picker'
import DatePanel from 'react-multi-date-picker/plugins/date_panel'
import Toolbar from 'react-multi-date-picker/plugins/toolbar'
import { DATE_FORMAT } from 'constants/dateFormats'
import {
  Select,
  TextArea,
  // TextField,
  DatePicker as OrcatecDatePicker,
} from 'components/UIKit'
import { CalendarOutlined } from '@ant-design/icons'
import styled from 'styled-components'
// import { Companies } from 'types/Company'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import { RecurringOptions } from 'features/Visits/components/Recurring/RecurringModal'
import Recurrence from 'features/Visits/components/Recurring/components/Recurrence'
// import { useState } from 'react'

const transformData = data => {
  const groupedData = data.reduce((acc, item) => {
    // If the source does not exist in the accumulator, add it
    if (!acc[item.source]) {
      acc[item.source] = []
    }
    // Push the current item into the respective source group
    acc[item.source].push({
      label: item.name,
      value: item.id,
      key: item.name + item.id,
    })
    return acc
  }, {})

  // Transform the grouped data into the desired structure
  return Object.keys(groupedData).map(key => ({
    label: key,
    options: groupedData[key],
  }))
}

interface Props {
  assignToOptions: AssignToOption[]
  job: JobType
  disabledTime: {
    matrixTimeStart: []
    matrixTimeEnd: []
  }
  error: Error
  setTech: (data: []) => void
  onChange: (e: { target: { name: string; value: unknown } }) => void
  setNotesEdit: () => void
  eventSettings: IEventSettings
  recurringOptions: RecurringOptions | null
  onOpenRecurringOptions: () => void
  isContact?: boolean
  isRecurringTooltipVisible?: boolean
  onTooltipClose: () => void
}

export const Job = ({
  job,
  disabledTime,
  onChange,
  error,
  setNotesEdit,
  eventSettings,
  assignToOptions,
  onTooltipClose,
  isRecurringTooltipVisible,
  recurringOptions,
  isContact,
  onOpenRecurringOptions,
}: Props) => {
  const { technicians: workers } = useTechnicians()

  // const [newRow, setNewRow] = useState(false)

  const { restrict_crud_source_type, start_hour, end_hour } = useAppSelector(
    selectDispatchSettings,
  )
  const owner = useAppSelector(isOwner)
  // const company = useAppSelector(selectCompany)
  const dictionary = useAppSelector(selectJobDictionary)

  const handleChangeTime = (time: moment.Moment | null, type: string) => {
    onChange({
      target: {
        name: type,
        value: time,
      },
    })
  }

  /* const handleChangeDate = (
    _: MaterialUiPickersDate,
    value?: string | null | undefined,
  ) => {
    onChange({
      target: {
        name: 'date',
        value: value ? moment(value).format('YYYY-MM-DD') : null,
      },
    })
  } */

  /* 
  const handleOnBlueWorkersNeeded = (e: React.FocusEvent<HTMLInputElement>) => {
    const { name, value } = e.target

    if (!+value)
      onChange({
        target: {
          name,
          value: 1,
        },
      })
  } */

  const handleChangeStartDate = (
    _: MaterialUiPickersDate,
    value?: string | null | undefined,
  ) => {
    onChange({
      target: {
        name: 'start_date',
        value: moment(value).format('YYYY-MM-DD'),
      },
    })
  }

  const handleChangeAssignTo = (_, value) => {
    onChange({
      target: {
        name: 'column_template_id',
        value,
      },
    })
  }

  /*  const renderTags = (value: (string | number)[]) => {
    return (
      <p style={{ paddingLeft: 10, color: '#000' }}>
        {assignToOptions.find(option => option.id === value[0])?.name}
        <span style={{ fontSize: 12 }}>
          {value?.length > 1 ? ` + ${value.length - 1} selected` : ''}
        </span>
        {'\xa0\xa0'}
      </p>
    )
  } */

  const handleDateChange = (_, options) => {
    onChange({
      target: {
        name: 'date',
        value: job.id
          ? options.validatedValue?.[0] || null
          : options.validatedValue,
      },
    })
  }

  if (!assignToOptions.length) return <p>...loading</p>

  // const isWestCost = company.id === Companies.WestCost

  return (
    <form autoComplete='off'>
      <Wrapper>
        <TextArea
          name='event_name'
          value={job.event_name}
          onChange={onChange}
          label={`${dictionary.singular} name`}
          maxLength={255}
          error={error?.event_name}
        />

        <Type>
          <Label>{`${dictionary.singular} type`}</Label>
          <AppointmentTypes
            // label={`${dictionary.singular} Type`}
            withoutLabel
            name='appointment_type_id'
            value={job.appointment_type_id}
            onChange={onChange}
            disableCRUD={!owner && restrict_crud_source_type}
          />
        </Type>

        <Select
          options={workers.map(worker => ({
            label: worker.name,
            value: worker.id,
          }))}
          label='Lead preferred worker'
          value={job.preferred_technician_id || ''}
          name='preferred_technician_id'
          onChange={e => onChange(e)}
          placeholder='Select lead prefered worker'
          error={error?.preferred_technician_id}
        />

        {eventSettings?.display_timezone && (
          <AppointmentTimezone
            onChange={onChange}
            value={job?.timezone || eventSettings?.timezone}
            defaultValue={eventSettings?.timezone}
          />
        )}

        <DateSection>
          {job?.is_recurring ? (
            <OrcatecDatePicker
              value={job?.start_date ? moment(job.start_date) : null}
              onChange={handleChangeStartDate}
              placeholder='Select date start'
              label='Start Date'
              allowClear={false}
              required
            />
          ) : (
            <Date error={!!error?.date}>
              <Label>{`${dictionary.singular} date`}</Label>
              <DatePicker
                multiple={!job.id}
                format={DATE_FORMAT}
                value={
                  Array.isArray(job.date)
                    ? job.date
                    : moment(job.date).format(DATE_FORMAT)
                }
                onChange={handleDateChange}
                plugins={[
                  <DatePanel key={'panel'} />,
                  <Toolbar
                    key='toolbar'
                    position='bottom'
                    names={{ today: '', deselect: '', close: 'OK' }}
                  />,
                ]}
                inputClass='styled-datepicker'
                placeholder='Select date'
              />

              <CalendarOutlined />

              {error?.date ? <ErrorText>{error.date}</ErrorText> : ''}
            </Date>
          )}
          {eventSettings?.show_promise_time && (
            <TimeSection>
              <CustomTimePicker
                title='Promised arrival time'
                name='time_start'
                onChange={(value, valueNum, name) =>
                  handleChangeTime(value ? moment(value, 'HH:mm ') : null, name)
                }
                value={
                  job.time_start ? moment(job.time_start).format('HH:mm') : null
                }
                endWorkTime={23}
                startWorkTime={0}
                error={error?.time_start}
                placeholder='Select time'
                withMinute={true}
                required={!!job.assign_to_matrix}
                step={+eventSettings?.promised_time_increment}
                style={{ width: '130px', height: 32 }}
                short
              />

              <CustomTimePicker
                name='time_end'
                onChange={(value, valueNum, name) =>
                  handleChangeTime(moment(value, 'HH:mm '), name)
                }
                value={
                  job.time_end ? moment(job.time_end).format('HH:mm') : null
                }
                endWorkTime={23}
                startWorkTime={0}
                error={error?.time_end}
                placeholder='Select time'
                withMinute={true}
                required={!!job.assign_to_matrix}
                step={+eventSettings?.promised_time_increment}
                short
                style={{ width: '130px', height: 30 }}
              />
            </TimeSection>
          )}
        </DateSection>

        {!job.id && !isContact && (
          <div style={{ marginTop: '-10px' }}>
            <Recurrence
              value={job.is_recurring}
              isTooltipVisible={isRecurringTooltipVisible}
              recurringOptions={recurringOptions}
              onChange={value =>
                onChange({
                  target: {
                    name: 'is_recurring',
                    value,
                  },
                })
              }
              onOpen={onOpenRecurringOptions}
              onTooltipClose={onTooltipClose}
            />
          </div>
        )}

        <AssagneeSection>
          <Select
            required
            label='Assigned to'
            name='column_template_id'
            mode={!job.id ? 'multiple' : undefined}
            value={job.column_template_id}
            options={transformData(assignToOptions)}
            onChange={handleChangeAssignTo}
            listItemHeight={32}
            error={error?.column_template_id}
          />

          {((Array.isArray(job?.column_template_id) &&
            job.column_template_id.some(id => typeof id === 'number')) ||
            ((!!job.id || job.is_recurring) &&
              typeof job.column_template_id === 'number')) && (
            <TimeSection>
              <CustomTimePicker
                title='Dispatch time'
                name='matrix_time_start'
                onChange={(value, valueNum, name) =>
                  handleChangeTime(moment(value, 'HH:mm '), name)
                }
                value={moment(job.matrix_time_start).format('HH:mm')}
                endWorkTime={end_hour - 1}
                startWorkTime={start_hour}
                error={error?.matrix_time_start}
                required={!!job.assign_to_matrix}
                placeholder='Select time'
                style={{ width: '130px', height: 30 }}
                disabledTime={
                  end_hour < 25 ? disabledTime?.matrixTimeStart : undefined
                }
                short
              />

              <CustomTimePicker
                name='matrix_time_end'
                onChange={(value, valueNum, name) =>
                  handleChangeTime(moment(value, 'HH:mm '), name)
                }
                value={
                  moment(job.matrix_time_end).hour() === 0
                    ? '24:00'
                    : moment(job.matrix_time_end).format('HH:mm')
                }
                endWorkTime={end_hour}
                startWorkTime={start_hour}
                error={error?.matrix_time_end}
                required={!!job.assign_to_matrix}
                disabledTime={[moment(job.matrix_time_start).hour()]}
                placeholder='Select time'
                short
                style={{ width: '130px', height: 30 }}
              />
            </TimeSection>
          )}
        </AssagneeSection>

        {/* {job?.is_recurring && (
          <RecurringOptions event={job} onChange={onChange} error={error} />
        )} */}

        {/* {isWestCost && (
          <TextField
            label='Days needed'
            name='count_days'
            value={job?.count_days}
            type='number'
            onChange={onChange}
            min={1}
            max={30}
            onBlur={e => {
              if (!e.target.value)
                onChange({ target: { name: 'count_days', value: 1 } })
            }}
            // InputProps={{ inputProps: { min: 1, max: 30 } }}
          />
        )} */}

        {job.id ? (
          <Notes setEdited={setNotesEdit} route={`/jobs/${job.id}/notes`} />
        ) : (
          <TextArea
            name='notes'
            value={job.notes}
            onChange={onChange}
            label={`${dictionary.singular} notes`}
            multiline
          />
        )}
      </Wrapper>
    </form>
  )
}

const ErrorText = styled.p`
  font-size: 12px;
  color: #f12832;
`
