import Modal from 'containers/MainContent/Orcatec/components/UI/Modal'
import { useState } from 'react'
import ContactsList from './components/ContactsList'
import styled from 'styled-components'
import { Badge, Collapse, Spin } from 'antd'
import { useContacts } from 'features/Notification/hooks/useContacts'
import { AssignToOption } from 'hooks/useEventAssignToOptions'
import { ContactI, ContactWithMessage } from './types'
import { useNotificationTemplatesList } from 'features/Notification/hooks/useNotificationTemplatesList'
import {
  ITemplate,
  NotificationModalType,
  NotificationTemplateType,
} from 'features/Notification/types'
import { Footer } from './components/ModalFooter/ModalFooter'
import { Button } from 'components/UIKit'
import { OtherContacts } from './components/OtherContacts/OtherContacts'
// import { parseEmailMessage } from 'features/Notification/helpers/parseEmailMessasge'
import { Job } from 'types/Jobs'
import { useAppSelector } from 'store/Orcatec/hooks'
import { selectAuthUser } from 'store/Orcatec/selectors/user'
import { selectCompany } from 'store/Orcatec/selectors/company'
import { useIndustryLogo } from 'features/Notification/hooks/useIndustryLogo'
import { useTimeSettings } from 'features/Notification/hooks/useTimeSettings'
import {
  currentTimeIsBetweenTzTime,
  parsePhoneNumbersBeforeSend,
} from './utils'
import moment from 'moment-timezone'
import {
  sendEmailNotificationV2,
  sendSMSNotificationV2,
} from 'api/NotificationModal'
import { useMessagingPhones } from 'features/Messaging/hooks/useMessagingPhones'
import { removeSpace, removeTagsSms } from './NotificationModal'
import {
  selectWordOrderItems,
  selectWorkOrder,
} from 'features/Project/slices/projectWorkOrdersSlice'
import { MessageSection } from './components/MessagesList/MessageSection'
import { selectWOSummaryContact } from 'features/Project/slices/projectWorkOrderContactsSlice'
import AppointmentNotificationHistory from 'containers/MainContent/Orcatec/CreateAppointmentV2/AppointmentForm/components/AppointmentNotificationHistory/AppointmentNotificationHistory'
import { openNotificationWithIcon } from 'helpers/notifications/openNotificationWithIcon'

const { Panel } = Collapse

export interface Recipients {
  subject: string
  from_name: string
  reply_to: string
  from_number: string
  recipients: Record<number, ContactWithMessage>
}

export interface OtherContacts {
  subject: string
  from_name: string
  reply_to: string
  from_number: string
  emails: {
    checked: boolean
    value: string[]
  }
  phone: {
    checked: boolean
    value: string
  }
  emailMessage: string
  phoneMessage: string
}

interface Props {
  title: string
  templateType: NotificationTemplateType
  notificationType: NotificationModalType
  industryId: number
  techs: ContactI[]
  entity: 'appointments' | 'proposals'
  entityId: number
  dispatchColumns?: AssignToOption[]
  onClose: () => void
  onSubmit: () => void
  event: Job
}

export const NotificationModalV2 = ({
  industryId,
  templateType,
  notificationType,
  title,
  techs,
  onClose,
  onSubmit,
  entity,
  entityId,
  event,
}: Props) => {
  const me = useAppSelector(selectAuthUser)
  const company = useAppSelector(selectCompany)

  const { contactList, loading: contactsLoading } = useContacts(
    entity,
    entityId,
  )
  const { industryLogo } = useIndustryLogo(industryId)
  const { timeSettings } = useTimeSettings()
  const { phones } = useMessagingPhones()
  const isMessagingEnabled = !!phones.length

  const workOrder = useAppSelector(selectWorkOrder)
  const { property } = useAppSelector(selectWOSummaryContact)
  const scopeOfWork = useAppSelector(selectWordOrderItems())

  const [confirmModal, showConfirmModal] = useState(false)
  const [isSending, setIsSending] = useState(false)
  const {
    templatesList,
  }: {
    templatesList: (ITemplate & { label: string })[]
  } = useNotificationTemplatesList(templateType)

  //Contacts
  const [selectedClients, setSelectedClients] = useState<Recipients>({
    subject: '',
    from_name: '',
    reply_to: '',
    from_number: '',
    recipients: {},
  })
  const [selectedTechs, setSelectedTechs] = useState<Recipients>({
    subject: '',
    from_name: '',
    reply_to: '',
    from_number: '',
    recipients: {},
  })
  const [otherContacts, setOtherContacts] = useState({
    subject: '',
    from_name: '',
    reply_to: '',
    from_number: '',
    emails: {
      checked: false,
      value: [],
    },
    phone: {
      checked: false,
      value: '',
    },
    emailMessage: '',
    phoneMessage: '',
  })

  const [historyModal, showHistoryModal] = useState(false)

  const handleRecipientsChange = (entity: 'client' | 'tech') => (
    recipients: Recipients,
  ) => {
    entity === 'client'
      ? setSelectedClients(recipients)
      : setSelectedTechs(recipients)
  }

  const handleOhterContactsChange = (otherContacts: OtherContacts) => {
    setOtherContacts(otherContacts)
  }

  // const handleCheckOther = (type: 'emails' | 'phone', value: boolean) => {
  //   setOtherContacts(prev => ({
  //     ...prev,
  //     [type]: {
  //       ...prev[type],
  //       checked: value,
  //     },
  //   }))
  // }

  const clientEmailsToSendNotification = Object.values(
    selectedClients.recipients,
  )
    .filter(entity => entity.emails?.length)
    .reduce((list, entity) => {
      entity?.emails?.forEach(email =>
        list.push({ email: email, body: entity.emailMessage }),
      )

      return list
    }, [])

  const techEmailsToSendNotification = Object.values(selectedTechs.recipients)
    .filter(entity => entity.emails?.length)
    .reduce((list, entity) => {
      entity?.emails?.forEach(email =>
        list.push({ email: email, body: entity.emailMessage }),
      )

      return list
    }, [])

  const clientPhonesToSendNotification = Object.values(
    selectedClients.recipients,
  )
    .filter(entity => entity.phones?.length)
    .reduce((list, entity) => {
      entity?.phones?.forEach(phone =>
        list.push({
          phone: parsePhoneNumbersBeforeSend([phone])[0],
          body: entity.phoneMessage,
        }),
      )

      return list
    }, [])

  const techPhonesToSendNotification = Object.values(selectedTechs.recipients)
    .filter(entity => entity.phones?.length)
    .reduce((list, entity) => {
      entity?.phones?.forEach(phone =>
        list.push({
          phone: parsePhoneNumbersBeforeSend([phone])[0],
          body: entity.phoneMessage,
        }),
      )

      return list
    }, [])

  const EMAILS = [
    ...clientEmailsToSendNotification,
    ...techEmailsToSendNotification,
    ...(otherContacts.emails.checked ? otherContacts.emails.value : []),
  ]

  const PHONES = [
    ...clientPhonesToSendNotification,
    ...techPhonesToSendNotification,
    ...(otherContacts.phone.checked ? [otherContacts.phone.value] : []),
  ]

  const handleMessageChange = (entity: 'client' | 'tech') => (
    type: 'emailMessage' | 'phoneMessage',
    contactId: number,
    message: string,
  ) => {
    const helperFunc = (prev: Recipients) => ({
      ...prev,
      recipients: {
        ...prev.recipients,
        [contactId]: {
          ...prev.recipients[contactId],
          [type]: message,
        },
      },
    })

    entity === 'client'
      ? setSelectedClients(helperFunc)
      : setSelectedTechs(helperFunc)
  }

  const handleOtherContactsMessageChange = (
    type: 'emailMessage' | 'phoneMessage',
    _contactId: number,
    message: string,
  ) => {
    setOtherContacts(prev => ({
      ...prev,
      [type]: message,
    }))
  }

  const noContactsSelected = !EMAILS.length && !PHONES.length

  const handleSubmit = async (isSendLater?: boolean, isSendNow?: boolean) => {
    const format = 'HH:mm:ss'
    const tz = timeSettings?.timezone
    const startTime = moment(timeSettings?.time_from, format).format(format)
    const endTime = moment(timeSettings?.time_to, format).format(format)
    const timeNow = moment.tz(tz).format(format)

    const sendAt = isSendLater
      ? moment(timeSettings?.time_from, 'HH:mm')
          .add(1, 'day')
          .format('YYYY-MM-DD HH:mm')
      : moment()
          .tz(tz)
          .add(1, 'minute')
          .format('YYYY-MM-DD HH:mm')

    const isCanSendNow = currentTimeIsBetweenTzTime(
      timeNow,
      tz,
      startTime,
      endTime,
      format,
    )
    const canSendNow = isSendLater || isSendNow || isCanSendNow

    if (!canSendNow) return showConfirmModal(true)

    try {
      setIsSending(true)

      if (clientEmailsToSendNotification.length) {
        const emailNotificationData = {
          ...selectedClients,
          send_at: sendAt,
          send_at_timezone: tz,
          type: notificationType,
          body: otherContacts.emailMessage,
          recipients: {
            clients: clientEmailsToSendNotification,
            technicians: [],
            other: [],
          },
        }

        await sendEmailNotificationV2(event.id, emailNotificationData)
      }

      if (techEmailsToSendNotification.length) {
        const emailNotificationData = {
          ...selectedTechs,
          send_at: sendAt,
          send_at_timezone: tz,
          type: notificationType,
          body: otherContacts.emailMessage,
          recipients: {
            clients: [],
            technicians: techEmailsToSendNotification,
            other: [],
          },
        }

        await sendEmailNotificationV2(event.id, emailNotificationData)
      }

      if (otherContacts.emails.checked && otherContacts.emails.value.length) {
        const emailNotificationData = {
          ...otherContacts,
          send_at: sendAt,
          send_at_timezone: tz,
          type: notificationType,
          body: otherContacts.emailMessage,
          recipients: {
            clients: [],
            technicians: [],
            other: otherContacts.emails.checked
              ? otherContacts.emails.value
              : [],
          },
        }

        await sendEmailNotificationV2(event.id, emailNotificationData)
      }

      if (isMessagingEnabled && PHONES.length) {
        if (clientPhonesToSendNotification.length) {
          const smsNotificationData = {
            send_at: sendAt,
            send_at_timezone: tz,
            type: notificationType,
            body: removeSpace(removeTagsSms(otherContacts.phoneMessage)),
            media: [],
            from_number: otherContacts.from_number,
            recipients: {
              clients: clientPhonesToSendNotification,
              technicians: [],
              other: [],
            },
          }

          await sendSMSNotificationV2(event.id, smsNotificationData)
        }

        if (techPhonesToSendNotification.length) {
          const smsNotificationData = {
            send_at: sendAt,
            send_at_timezone: tz,
            type: notificationType,
            body: removeSpace(removeTagsSms(otherContacts.phoneMessage)),
            media: [],
            from_number: otherContacts.from_number,
            recipients: {
              clients: [],
              technicians: techPhonesToSendNotification,
              other: [],
            },
          }

          await sendSMSNotificationV2(event.id, smsNotificationData)
        }

        if (otherContacts.phone.checked && [otherContacts.phone.value].length) {
          const smsNotificationData = {
            send_at: sendAt,
            send_at_timezone: tz,
            type: notificationType,
            body: removeSpace(removeTagsSms(otherContacts.phoneMessage)),
            media: [],
            from_number: otherContacts.from_number,
            recipients: {
              clients: [],
              technicians: [],
              other: otherContacts.phone.checked
                ? parsePhoneNumbersBeforeSend([otherContacts.phone.value])
                : [],
            },
          }

          await sendSMSNotificationV2(event.id, smsNotificationData)
        }
      }

      onSubmit?.()
    } catch (error) {
      openNotificationWithIcon('error', {
        message: 'Oops',
        description: error?.response?.data?.message || 'Something went wrong',
      })
    } finally {
      setIsSending(false)
    }
  }

  const toggleNotificationsHistory = () => showHistoryModal(!historyModal)

  /* const sendLetterWithNativeEmail = (): void => {
    let emailsList: string[] = [][
      // otherEmails.emails?.map?.(otherEmail => {
      //   emailsList.push(otherEmail)
      // })
      (Object.values(selectedClients), Object.values)
    ]?.clients?.map(email => emailsList.push(email))

    // eslint-disable-next-line no-useless-escape
    // const linkRegex = /(?<![a-zA-Z0-9`~!@#$%^&*()-_+={}\[\]|\\:;"'<>,.?/])link(?![a-zA-Z0-9`~!@#$%^&*()-_+={}\[\]|\\:;"'<>,.?/])/gi
    const linkRegex = /(^|[^a-zA-Z0-9`~!@#$%^&*()-_+={}[\]|\\:;"'<>,.?/])link([^a-zA-Z0-9`~!@#$%^&*()-_+={}[\]|\\:;"'<>,.?/]|$)/gi

    // eslint-enable no-useless-escape
    const cleanedMessage = emailData.message
      .replace(/&nbsp;/g, ' ')
      .replace(/&amp;/g, '&')
      .replace(/<\/?[^>]+(>|$)/g, ' ')
      .replace(linkRegex, `${proposalLink}`)

    const toEmails = encodeURIComponent(emailsList.join(','))
    const subject = encodeURIComponent(emailData.subject)

    const body = encodeURIComponent(cleanedMessage)

    const prepareEmail = `mailto:${toEmails}?subject=${subject}&body=${body}`

    const prepareEmailWithLink = prepareEmail

    window.location.href = prepareEmailWithLink
    setShowModal(false)

    emailsList = []
  } */

  return (
    <Modal
      open
      title={title}
      footer={
        <Footer
          onSubmit={() => handleSubmit()}
          onCancel={onClose}
          disableSend={noContactsSelected}
          loading={isSending}
          onShowHistory={toggleNotificationsHistory}
        />
      }
      width={750}
      onCancel={onClose}
      bodyStyle={{
        maxHeight: '80vh',
        overflow: 'auto',
        backgroundColor: '#f9f9f9',
      }}
    >
      <Spin spinning={contactsLoading}>
        <Section>
          <Contacts>
            {!!contactList.length && (
              <ContactsList
                title='Contacts'
                data={contactList}
                selectedContacts={selectedClients}
                onChange={handleRecipientsChange('client')}
                companyPhones={phones}
                templatesList={templatesList}
                notificationType={notificationType}
                industryId={industryId}
                me={me}
                company={company}
                scopeOfWork={scopeOfWork}
                workOrder={workOrder}
                property={property}
                event={event}
              />
            )}
            {!!techs.length && (
              <ContactsList
                title='Technicians'
                data={techs}
                selectedContacts={selectedTechs}
                onChange={handleRecipientsChange('tech')}
                companyPhones={phones}
                templatesList={templatesList}
                notificationType={notificationType}
                industryId={industryId}
                me={me}
                company={company}
                scopeOfWork={scopeOfWork}
                workOrder={workOrder}
                property={property}
                event={event}
              />
            )}
            <OtherContacts
              // emails={otherContacts.emails}
              // phone={otherContacts.phone}
              otherContacts={otherContacts}
              onChange={handleOhterContactsChange}
              // onCheck={handleCheckOther}
              companyPhones={phones}
              templatesList={templatesList}
              notificationType={notificationType}
              industryId={industryId}
              me={me}
              company={company}
              scopeOfWork={scopeOfWork}
              workOrder={workOrder}
              property={property}
              event={event}
            />
          </Contacts>
        </Section>

        <Section>
          <Collapse ghost>
            <Panel
              header={
                <Badge
                  count={EMAILS.length + PHONES.length}
                  offset={[10, 5]}
                  color='#4285f4'
                >
                  <SectionTitle>Messages</SectionTitle>
                </Badge>
              }
              key='1'
            >
              <MessageSection
                industryLogo={industryLogo}
                selectedClients={selectedClients.recipients}
                selectedTechs={selectedTechs.recipients}
                otherContacts={otherContacts}
                onMessageChange={handleMessageChange}
                onOtherContactsMessageChange={handleOtherContactsMessageChange}
              />
            </Panel>
          </Collapse>
        </Section>

        {confirmModal && (
          <Modal
            wrapClassName='confirm-modal-wrapper'
            title={'Confirmation'}
            open
            // onOk={handleConfirmOk}
            onCancel={() => showConfirmModal(false)}
            width={750}
            footer={null}
            destroyOnClose
          >
            <p>
              {`You are trying to send a notification during a period that does not match business hours (${moment(
                timeSettings.time_from,
                'HH:mm:ss',
              ).format('hh:mm a')} - ${moment(
                timeSettings.time_to,
                'HH:mm:ss',
              ).format('hh:mm a')}) in the settings.
          Press 'Send Later' to send on the next business day or press 'Send Now' to send it immediately.`}
            </p>
            <div className='buttons-row'>
              <Button
                // disabled={isSubmitDisable}
                onClick={() => handleSubmit(true, false)}
              >
                Send Later
              </Button>
              <Button
                // disabled={isSubmitDisable}
                onClick={() => handleSubmit(false, true)}
              >
                Send Now
              </Button>
            </div>
          </Modal>
        )}

        {historyModal && (
          <AppointmentNotificationHistory
            appointment={event}
            showModal={historyModal}
          />
        )}
      </Spin>
    </Modal>
  )
}

const Contacts = styled.div`
  & > * {
    padding-bottom: 20px;
    border-bottom: 1px solid #c1c1c1;
    margin-bottom: 20px;
  }
`

const SectionTitle = styled.p`
  font-weight: 500;
  font-size: 18px;
`

const Section = styled.div`
  background-color: #fff;
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 30px;
`
