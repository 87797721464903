import { Radio, Spin } from 'antd'
import { Button } from 'components/UIKit'
import Modal from 'containers/MainContent/Orcatec/components/UI/Modal'
import styled from 'styled-components'

interface Props {
  actionScope: 'Edit' | 'Delete'
  value: number
  loading: boolean
  onChange: (value: number) => void
  onSave: () => void
  onCancel: () => void
}

export const ActionScopeModal = ({
  actionScope,
  value,
  loading,
  onChange,
  onSave,
  onCancel,
}: Props) => {
  const isDelete = actionScope === 'Delete'

  return (
    <Modal
      open
      title={`${actionScope} recurring event`}
      onOk={onSave}
      onCancel={onCancel}
      okButtonProps={{ loading }}
      width={300}
      centered
      footer={
        <Footer>
          <Button onClick={onCancel}>Cancel</Button>
          <Button
            danger={isDelete}
            type={isDelete ? 'default' : 'primary'}
            onClick={onSave}
            loading={loading}
          >
            {isDelete ? 'Delete' : 'Save'}
          </Button>
        </Footer>
      }
    >
      <Spin spinning={loading}>
        <Radio.Group onChange={e => onChange(e.target.value)} value={value}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: 10,
            }}
          >
            <Radio value={1}>This event</Radio>
            <Radio value={2}>This and following events</Radio>
            <Radio value={3}>All events</Radio>
          </div>
        </Radio.Group>
      </Spin>
    </Modal>
  )
}

const Footer = styled.div`
  display: flex;
  gap: 5px;
  justify-content: flex-end;
`
