import { getValueFromLS, setValueToLS } from 'hooks/useLocalStorage'
import { useCallback, useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'store/Orcatec/hooks'
import { getQueueEvents } from 'features/Dispatch/dispatchSlice'
import { QueueRow } from '../QueueRow/Queue'
import { Skeleton } from '@material-ui/lab'
import { useListenChangeQueue } from 'containers/MainContent/Orcatec/Matrix/listeners'
import { selectSortedQueueList } from 'features/Dispatch/queueSlice'
import ClosedQueue from '../ClosedQueue'
import { QueueFilters } from '../QueueFilters/QueueFilters'

export const QueueList = props => {
  const [queueState, setQueueState] = useState<Record<string, boolean>>({})
  const [mounted, setMounted] = useState(false)

  const queues = useAppSelector(selectSortedQueueList)
  // const me = useAppSelector(state => state.orcatec.user.me)
  const dispatch = useAppDispatch()

  const matrixSettings = getValueFromLS('matrixSettings')

  useEffect(() => {
    dispatch(getQueueEvents())
  }, [])

  useEffect(() => {
    if (Object.keys(queueState).length) {
      setValueToLS('matrixSettings', {
        ...matrixSettings,
        pageView: 1,
        queueState,
      })
    }
  }, [queueState])

  const changeQueueListener = useCallback(() => {
    // if (data.userId === me.id) return

    dispatch(getQueueEvents())
  }, [])

  useListenChangeQueue(changeQueueListener)

  useEffect(() => {
    if (!queues.length || mounted) return

    setQueueState(
      queues.reduce<Record<string, boolean>>((acc, { title }) => {
        acc[title] = matrixSettings?.queueState?.[title] ?? false

        return acc
      }, {}),
    )

    setMounted(true)
  }, [queues, mounted])

  const toggleQueueContent = (title: string) => {
    setQueueState(prev => ({
      ...prev,
      [title]: !prev[title as keyof typeof queueState],
    }))
  }

  const filterQueuesByState = (opened: boolean) =>
    queues.filter(queue =>
      opened ? !!queueState[queue.title] : !queueState[queue.title],
    )

  return (
    <div className='queues'>
      <QueueFilters />
      <div style={{ display: 'flex', gap: 10 }}>
        <div className='queues-icons'>
          {queues?.length ? (
            <>
              {filterQueuesByState(false).map(queue => (
                <ClosedQueue
                  key={queue.id}
                  data={queue}
                  onClick={() => toggleQueueContent(queue.title)}
                />
              ))}
            </>
          ) : (
            <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
              <Skeleton width={150} height={20} />
              <Skeleton width={150} height={20} />
              <Skeleton width={150} height={20} />
              <Skeleton width={150} height={20} />
              <Skeleton width={150} height={20} />
            </div>
          )}
        </div>
      </div>
      <div>
        {filterQueuesByState(true)?.map(queue => (
          <QueueRow
            key={queue.id}
            data={queue}
            toggleContent={toggleQueueContent}
            {...props}
          />
        ))}
      </div>
    </div>
  )
}
