import { InputNumber, InputNumberProps } from 'antd'
import withLabelAndError from '../helpers/Wrapper'

const NumberField = ({ ...props }) => {
  return (
    <InputNumber
      status={props.error ? 'error' : undefined}
      style={{ width: '100%' }}
      autoComplete='off'
      {...props}
    />
  )
}

export const NumberFieldWithLabelAndError = withLabelAndError<InputNumberProps>(
  NumberField,
)
