import axiosOrcatec from 'api/axiosInstance'
import { GeneralTableParams } from 'containers/MainContent/Orcatec/components/Table/types'
import { EventGroup } from './types'
import { RecurringOptions } from './components/Recurring/RecurringModal'

const getGroupEvents = (groupId: number, params: GeneralTableParams) => {
  return axiosOrcatec
    .get(`/events/${groupId}/group/list`, { params })
    .then(response => response.data)
}

const getEventGroup = (groupId: number) => {
  return axiosOrcatec
    .get<EventGroup>(`/events/${groupId}/group`)
    .then(response => response.data)
}

const updateEventGroup = (groupId: number, data: object) => {
  return axiosOrcatec
    .put(`/events/${groupId}/group`, data)
    .then(response => response.data)
}

const deleteEventGroup = (groupId: number) => {
  return axiosOrcatec
    .delete(`/events/${groupId}/group`)
    .then(response => response.data)
}

export const EventGroupAPI = {
  getGroupEvents,
  getEventGroup,
  updateEventGroup,
  deleteEventGroup,
}

//Recurring

const getRecurringEvents = (parentId: number, params: GeneralTableParams) => {
  return axiosOrcatec
    .get(`/events/${parentId}/recurring/list`, { params })
    .then(response => response.data)
}

const getRecurringSettings = (parentId: number) => {
  return axiosOrcatec
    .get<RecurringOptions>(`/events/${parentId}/recurring/setting`)
    .then(response => response.data)
}

const updateRecurringSettings = (parentId: number, data: object) => {
  return axiosOrcatec
    .put(`/events/${parentId}/recurring/setting`, data)
    .then(response => response.data)
}

export const RecurringEventsAPI = {
  getRecurringEvents,
  getRecurringSettings,
  updateRecurringSettings,
}
