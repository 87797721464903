import styled from 'styled-components'

export const Wrapper = styled.div`
  border: 1px solid #d6d6d6;
  border-radius: 4px;

  .current {
    border: 1px solid;
    pointer-events: none;

    td {
      background-color: #eee;
    }
  }
`

export const Heading = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px;

  h6 {
    margin: 0;
  }
`

export const Content = styled.div`
  overflow: scroll;
  padding-top: 10px;
  border-top: 1px solid #d6d6d6;

  & th.ant-table-cell {
    overflow: hidden;
    white-space: normal;
    text-overflow: ellipsis;
    text-align: left;
  }
`
