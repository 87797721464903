import { GeneralTableParams } from 'containers/MainContent/Orcatec/components/Table/types'
import { DispatchEvent } from 'features/Dispatch/types'
import { RecurringEventsAPI } from 'features/Visits/api'
import { useCallback, useEffect, useState } from 'react'

export const useRecurringEvents = (
  parentId: number,
  params: GeneralTableParams,
  revalidate?: number,
) => {
  const [events, setEvents] = useState<DispatchEvent[]>([])
  const [pagination, setPagination] = useState({
    current_page: 1,
    per_page: 10,
    total: 10,
  })
  const [loading, setLoading] = useState(false)

  const fetchData = useCallback(async () => {
    if (!parentId) return

    setLoading(true)

    try {
      const res = await RecurringEventsAPI.getRecurringEvents(parentId, params)

      setEvents(res.data)
      setPagination({
        current_page: res.meta.current_page,
        per_page: res.meta.per_page,
        total: res.meta.total,
      })
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }, [parentId, params?.page, params?.per_page])

  useEffect(() => {
    fetchData()
  }, [fetchData, revalidate])

  return { events, loading, pagination }
}
