import { Empty, Table, Tooltip } from 'antd'
import { ColumnsType, TablePaginationConfig } from 'antd/lib/table'
import AppointmentStatus from 'containers/MainContent/Orcatec/components/AppointmentStatus/AppointmentStatus'
import ToggleButton from 'containers/MainContent/Orcatec/components/buttons/ToggleButton/ToggleButton'
import moment from 'moment-timezone'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { getRelateJobs } from 'store/Orcatec/actions/jobs'
import { useAppSelector } from 'store/Orcatec/hooks'
import { selectRelatedJobs } from 'store/Orcatec/reducers/jobs'
import { Job } from 'types/Jobs'
import { Heading, Wrapper, Content } from './RelatedJobs.styles'
import { JobForm } from '../JobPage/components/JobForm/JobForm'
import { selectJobDictionary } from 'store/Orcatec/selectors/company'
import { InfoPlate } from 'components/UIKit'

interface Props {
  proposalId: number
  currentJobId?: number
  isDisableEvents?: boolean
  hideToggle?: boolean
  isWorkOrder?: boolean
  isSchedule?: boolean
  isProcessingRecurring?: boolean
}

export const RelatedJobs = ({
  proposalId,
  currentJobId,
  hideToggle,
  isWorkOrder,
  isSchedule,
  isProcessingRecurring,
}: Props) => {
  const dictionary = useAppSelector(selectJobDictionary)
  const [content, showContent] = useState(!!hideToggle)
  const [job, setJob] = useState<Job | null>(null)
  const [openJob, setOpenJob] = useState(false)
  const [params, setParams] = useState({
    page: 1,
    per_page: 10,
  })

  const { loading, relatedJobs, pagination } = useAppSelector(selectRelatedJobs)
  const dispatch = useDispatch()

  const key = isWorkOrder ? 'wo_proposal_id' : 'proposal_id'

  useEffect(() => {
    if (proposalId) dispatch(getRelateJobs({ [key]: proposalId, ...params }))
  }, [params, proposalId])

  const handleClose = () => {
    dispatch(getRelateJobs({ [key]: proposalId }))
    setJob(null)
    setOpenJob(false)
  }

  const handleTableChange = (pagination: TablePaginationConfig) => {
    setParams({
      page: pagination.current || 1,
      per_page: pagination.pageSize || 10,
    })
  }

  const columns: ColumnsType<Job> = [
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: '7em',
      align: 'left',
      render: (status: number) => <AppointmentStatus statusId={status} />,
    },
    {
      title: `${dictionary.singular} ID`,
      dataIndex: 'job_code',
      key: 'job_code',
      align: 'left',
      render: (text, record) =>
        record.id !== currentJobId ? (
          <Link className='link' to={`/jobs/${record.id}`} target='_blank'>
            {text}
          </Link>
        ) : (
          text
        ),
    },
    {
      title: `${dictionary.singular} Name`,
      dataIndex: 'event_name',
      key: 'event_name',
      align: 'left',
      render: value =>
        value?.length > 20 ? (
          <Tooltip title={value}>{value.slice(0, 15) + '...'}</Tooltip>
        ) : (
          value
        ),
    },
    {
      title: `${dictionary.singular} Type`,
      dataIndex: 'job_type',
      key: 'job_type',
      align: 'left',
    },
    {
      title: `${dictionary.singular} Category`,
      dataIndex: 'job_category',
      key: 'job_category',
      align: 'left',
    },
    {
      title: 'Work Completion Dates',
      dataIndex: 'date',
      key: 'date',
      align: 'left',
      render: (date, record) => renderTime(record),
    },
    {
      title: 'Preferred Worker',
      dataIndex: 'preferred_workers',
      key: 'preferred_workers',
      align: 'left',
    },
    {
      title: 'Assigned to',
      dataIndex: 'tech',
      key: 'tech',
      align: 'left',
    },
    {
      title: `${dictionary.singular} Notes`,
      dataIndex: 'notes',
      key: 'notes',
      render: array =>
        array?.[0]?.length > 50
          ? `${array?.[0]?.substring(0, 50)}...`
          : array[0],
    },
  ]

  const toggleContent = () => showContent(!content)

  if (!relatedJobs.length && isWorkOrder && !isSchedule)
    return (
      <Empty
        style={{ marginTop: '10px' }}
        description={`There are not related ${dictionary.plural} yet`}
      />
    )

  return (
    <Wrapper>
      {!hideToggle && (
        <Heading>
          <ToggleButton show={content} toggle={toggleContent} />
          <h6>{`Related ${dictionary.plural}`}</h6>({pagination?.total})
          {isProcessingRecurring && (
            <div style={{ marginLeft: 50 }}>
              <InfoPlate type='info' size='small' width='auto'>
                Creating recurring events in progress.
              </InfoPlate>
            </div>
          )}
        </Heading>
      )}

      {content && (
        <Content>
          <Table
            columns={columns}
            dataSource={relatedJobs}
            size='small'
            pagination={
              pagination.total > 10
                ? {
                    current: pagination.current_page,
                    total: pagination.total,
                    pageSize: pagination.per_page,
                    showSizeChanger: true,
                  }
                : false
            }
            onChange={handleTableChange}
            loading={loading}
            rowClassName={record =>
              record.id === currentJobId ? 'current' : ''
            }
            rowKey='id'
          />
        </Content>
      )}
      <JobForm
        onCloseDrawer={handleClose}
        visibleDrawer={openJob}
        drawerView
        jobId={job?.id}
        matrix
      />
    </Wrapper>
  )
}

const renderTime = ({ date, time_start, time_end }: Job) =>
  `${date ? moment(date).format('MM/DD/YYYY') : '(Queued)'} ${
    time_start ? moment(time_start, 'HH:mm:ss').format('ha') + '\xa0-\xa0' : ''
  }${time_end ? moment(time_end, 'HH:mm:ss').format('ha') : ''}`
