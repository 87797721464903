import styled from 'styled-components'
import { Error } from 'types/Error'

export const weekDays = {
  0: 'Sun',
  1: 'Mon',
  2: 'Tue',
  3: 'Wed',
  4: 'Thu',
  5: 'Fri',
  6: 'Sat',
} as const

interface Props {
  error: Error | null
  value: number[]
  disabled: boolean
  onChange: (value: number[]) => void
}

export const WeekDays = ({ error, value, disabled, onChange }: Props) => {
  const handleChange = (day: number) => {
    if (disabled) return

    const newValue = value.includes(day)
      ? value.filter(v => v !== day)
      : [...value, day]

    onChange(newValue.sort((a, b) => a - b))
  }

  return (
    <Wrapper>
      <List>
        {Object.entries(weekDays).map(([key, label]) => {
          const day = Number(key)

          return (
            <Day
              key={day}
              checked={value?.includes(day)}
              onClick={() => handleChange(day)}
              error={!!error?.each}
              // disabled={disabled}
            >
              {label}
            </Day>
          )
        })}
      </List>
      {error?.each && <ErrorText>* Select days for recurring events</ErrorText>}
    </Wrapper>
  )
}

const Wrapper = styled.div``
const List = styled.div`
  display: flex;
  gap: 5px;
`

const Day = styled.p<{ checked: boolean; error?: boolean; disabled?: boolean }>`
  display: flex;
  border: 1px solid ${({ error }) => (error ? '#ee6161' : '#fff')};
  border-radius: 50%;
  background-color: ${({ checked, disabled }) =>
    !checked ? 'rgb(241, 243, 244)' : disabled ? '#bcbbbb' : '#4285f4'};
  color: ${({ checked }) => (checked ? '#fff' : '#70757a')};
  /* background-color: rgb(241, 243, 244);
    color: #70757a; */
  flex: 0 0 30px;
  height: 30px;
  justify-content: center;
  align-items: center;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  font-size: 12px;
`

const ErrorText = styled.p`
  margin-top: 4px;
  font-size: 12px;
  color: #ee6161;
`
