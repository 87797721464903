import { RecurringOptions } from '../../RecurringModal'
import { RecurringInterval } from 'types/Appointment'
import styled from 'styled-components'
import { Select } from 'components/UIKit'
import moment from 'moment-timezone'
import { DATE_FORMAT } from 'constants/dateFormats'
import { EditIcon } from 'containers/MainContent/Orcatec/components/Icons/CommonIcons'
import { getModuleStatus } from 'store/Orcatec/reducers/settings/subscription'
import { useAppSelector } from 'store/Orcatec/hooks'
import { openNotificationWithIcon } from 'helpers/notifications/openNotificationWithIcon'
import { Popover } from 'antd'
import { CloseOutlined } from '@ant-design/icons'

const generateOptionValue = (value: RecurringOptions) => {
  const { interval, repeat_every, ending_options } = value

  if (!interval || !repeat_every || !ending_options) return 'repeat options'

  const single = {
    1: 'Daily',
    2: 'Weekly',
    3: 'Monthly',
  }

  const firstPart =
    repeat_every === 1
      ? single[interval]
      : `Every ${repeat_every} ${RecurringInterval[interval].toLowerCase()}s`

  const endingPart =
    ending_options.end_type === 1
      ? `, until ${moment(ending_options.value).format(DATE_FORMAT)}`
      : ending_options.end_type === 2
      ? `, ${ending_options.value} times`
      : `, until cancelled`
  return firstPart + endingPart
}

interface Props {
  recurringOptions: RecurringOptions | null
  value: boolean
  isTooltipVisible?: boolean
  onChange: (value: boolean) => void
  onOpen: () => void
  onTooltipClose?: () => void
}

export const Recurrence = ({
  value,
  recurringOptions,
  isTooltipVisible,
  onChange,
  onOpen,
  onTooltipClose,
}: Props) => {
  const isRecurringModuleActive = useAppSelector(state =>
    getModuleStatus(state, 'recurring_appointments'),
  )

  const handleChange = (_e, value: boolean) => {
    onTooltipClose?.()

    if (!isRecurringModuleActive) {
      return openNotificationWithIcon('info', {
        message: `Module is off`,
        description: `You have no permission to perform this action. Please, choose 'Recurring Events' in Optional Features`,
      })
    }

    onChange(value)
  }

  return (
    <RepeatSection>
      <Popover
        title={
          <Title>
            <p>Recurring moved</p>
            <span onClick={onTooltipClose} style={{ cursor: 'pointer' }}>
              <CloseOutlined />
            </span>
          </Title>
        }
        open={isTooltipVisible}
        content='You can select repeat options for recurring events'
        // overlayStyle={{ width: 250 }}
      >
        <Select
          label='Repeat options'
          size='small'
          name='is_recurring'
          value={value}
          onChange={handleChange}
          options={[
            { value: false, label: 'Does not repeat' },
            { value: true, label: 'Repeat' },
          ]}
        />
      </Popover>

      {!!value && !!recurringOptions && (
        <Link onClick={onOpen}>
          {generateOptionValue(recurringOptions)}
          <EditIcon size={12} />
        </Link>
      )}
    </RepeatSection>
  )
}

const RepeatSection = styled.div`
  display: grid;
  gap: 10px;
  grid-template-columns: 150px max-content;
  align-items: end;
  /* margin-top: -20px; */
`

const Link = styled.p`
  cursor: pointer;
  color: #4285f4;
  display: inline-flex;
  gap: 5px;
  align-items: center;
`

const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`
