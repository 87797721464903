import axiosOrcatec, { CancelToken } from './axiosInstance'
import moment from 'moment-timezone'
import axios from 'axios'

export let cancelProposalActivityRequest = () => {}

let cancelToken

/**
 * Fetch properties list.
 *
 * @return {*}
 */
export const fetchProposalsList = params => {
  if (cancelToken) {
    cancelToken.cancel('Same request is executing')
  }
  cancelToken = axios.CancelToken.source()
  return axiosOrcatec
    .get('proposals', { params, cancelToken: cancelToken.token })
    .then(response => response.data)
    .catch(error => {
      if (axios.isCancel(error)) {
        console.error('Request canceled:', error?.message)
        const cancellationError = new Error('Request canceled')
        cancellationError.isCancellationError = true
        throw cancellationError
      }

      throw error
    })
}

export const fetchProposalsUserList = (params = {}) => {
  return axiosOrcatec
    .get('/proposals/filters/users', params)
    .then(response => response.data)
}

export const fetchProposalsStatusList = (params = {}) => {
  return axiosOrcatec
    .get('/setting/proposal/custom-statuses-with-proposal', params)
    .then(response => response.data)
}

export const getProjectsBoard = params =>
  axiosOrcatec
    .get('/proposal/board', { params })
    .then(response => response.data)

export const getProjectBoardTotals = params =>
  axiosOrcatec
    .get('/proposal/board-totals', { params })
    .then(response => response.data)

export const updateProjectStatus = (projectId, data, params) =>
  axiosOrcatec
    .put(`/proposal/${projectId}/board/update`, data, { params })
    .then(response => response.data)

export const fetchUserListForTable = (params = {}) => {
  return axiosOrcatec
    .get('/table/users', { params })
    .then(response => response.data)
}

export const exportProposalsTable = params =>
  axiosOrcatec
    .get(`/proposals/export`, {
      responseType: 'blob',
      params,
    })
    .then(res => {
      const url = window.URL.createObjectURL(new Blob([res.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `Projects_${moment().format('DD-MM')}.xls`)
      document.body.appendChild(link)
      link.click()
    })

/**
 * Fetch proposal code.
 *
 * @return {*}
 */
export const fetchProposalCode = id => {
  return axiosOrcatec
    .get(`/proposals/code?userId=${id}`)
    .then(response => response.data)
}

/**
 * Fetch property.
 *
 * @param id
 * @return {Promise<AxiosResponse<any> | never>}
 */
export const fetchProposal = (id, tabId) => {
  return axiosOrcatec
    .get(`/proposals/${id}${tabId ? `?tab_id=${tabId}` : ''}`)
    .then(response => response.data)
}

/**
 * Create property.
 *
 * @param data
 * @return {*}
 */
export const createProposal = data => {
  return axiosOrcatec.post('/proposals', data).then(response => response.data)
}
/**
 * Create property.
 *
 * @return {*}
 */
export const fetchOrdersHistory = tabId => {
  return axiosOrcatec
    .get(`/proposal-orders?tabId=${tabId}`)
    .then(response => response.data)
}
/**
 * Create property.
 *
 * @param data
 * @return {*}
 */
export const orderEquipment = data => {
  return axiosOrcatec
    .post('/proposal-orders', data)
    .then(response => response.data)
}
/**
 * Create property.
 *
 * @param tabId
 * @param status default = 1 pending = 2 ordered = 3
 * @return {*}
 */
export const changeOrderEquipmentStatus = (tabId, status) => {
  return axiosOrcatec
    .get(`/proposal-orders/change-status?tabId=${tabId}&status=${status}`)
    .then(response => response.data)
}
/**
 * Create property.
 *
 * @param tabId
 * @param status default = 1 pending = 2 payed = 3
 * @return {*}
 */
export const changePaymentStatus = (tabId, status) => {
  return axiosOrcatec
    .patch(`/payments/change-status?tabId=${tabId}&payment_status=${status}`)
    .then(response => response.data)
}

/**
 * Create property.
 *
 * @param modules
 * @param priority_status 1/0
 * @return {*}
 */
export const updateProposalOptionalModules = (tabId, modules) => {
  return axiosOrcatec
    .put(`/proposals/update-tab-modules/${tabId}`, { modules })
    .then(response => response.data)
}

/**
 * Update property.
 *
 * @param id
 * @param data
 * @return {Promise<T | never>}
 */
export const updateProposal = (id, data) => {
  if (!id) return

  return axiosOrcatec
    .put(`/proposals/${id}`, data)
    .then(response => response.data)
}

/**
 * Delete property.
 *
 * @param id
 * @return {*}
 */
export const deleteProposalTab = id => {
  return axiosOrcatec
    .delete(`/proposals/delete-tab/${id}`)
    .then(response => response.data)
}

/**
 * Delete property.
 *
 * @param id
 * @return {*}
 */
export const deleteProposal = id => {
  return axiosOrcatec.delete(`proposals/${id}`).then(response => response.data)
}

export const getDiffPrices = params => {
  return axios
    .get(`${process.env.REACT_APP_API_URL}proposals/get-diff-prices?${params}`)
    .then(response => response.data)
}

export const getDataOfPrice = params =>
  axios
    .get(`${process.env.REACT_APP_API_URL}proposals/get-data-of-price`, {
      params,
    })
    .then(response => response.data)

export const itemsFilter = params => {
  return axiosOrcatec
    .get('/setting/items/get-items-for-proposal', { params: params })
    .then(response => response.data)
}

export const fetchProposalTemplates = params => {
  return axiosOrcatec
    .get(`/templates`, { params })
    .then(response => response.data)
}

export const postProposalTemplate = data => {
  return axiosOrcatec.post(`/templates`, data).then(response => response.data)
}

export const fetchProposalTemplate = id => {
  return axiosOrcatec.get(`/templates/${id}`).then(response => response.data)
}

export const putProposalTemplate = (id, data) => {
  return axiosOrcatec
    .put(`/templates/${id}`, data)
    .then(response => response.data)
}

export const deleteProposalItem = (id, type) => {
  //type = Equipment, Instant, Rebate, ScopeOfWork, Warranty
  return axiosOrcatec
    .delete(`/proposals/delete-item/${id}?type=${type}`)
    .then(response => response.data)
}

export const deleteProposalSection = id => {
  return axiosOrcatec
    .delete(`/proposals/delete-section/${id}`)
    .then(response => response.data)
}

export const deleteProposalEntities = data => {
  if (Object.values(data).flat().length) {
    return axiosOrcatec
      .delete('/proposals/delete-entities', { data })
      .then(response => response.data)
  }
  return new Promise(r => r())
}

export const deleteProposalTemplate = id => {
  return axiosOrcatec.delete(`/templates/${id}`).then(response => response.data)
}
export const fetchClientsRelatives = id => {
  return axiosOrcatec
    .get(`/clients/get-relatives?property_id=${id}`)
    .then(response => response.data)
    .catch(err => console.error(err))
}
export const copyProposal = id => {
  return axiosOrcatec
    .get(`/proposals/copy/${id}`)
    .then(response => response.data)
}
export const duplicateProject = (id, projectCode) => {
  return axiosOrcatec
    .get(`/proposals/copy/${id}?code=${projectCode}`)
    .then(response => response.data)
}

export const fetchRemindersList = data => {
  return axiosOrcatec
    .get(
      `/reminders?limit=${data.limit}&page=${data.page}&order=${data.order}&sort=${data.sort}&entity_id=${data.entity_id}&entity_type=${data.entity_type}`,
    )
    .then(response => response.data)
}
export const postReminder = data => {
  return axiosOrcatec.post(`/reminders`, data).then(response => response.data)
}
export const emailPost = data => {
  return axiosOrcatec
    .post(`/proposal-service-contract-emails`, data)
    .then(response => response.data)
}
export const editReminder = (id, data) => {
  return axiosOrcatec
    .put(`/reminders/${id}`, data)
    .then(response => response.data)
}
export const deleteReminder = id => {
  return axiosOrcatec.delete(`/reminders/${id}`).then(response => response.data)
}

export const getProposalPreviewData = (proposal_id, tab_id, isPrint) => {
  const params = {
    /*  entity_type: 1, //proposal
    entity_id: proposal_id,
    timezone: moment.tz.guess(), */
  }
  if (tab_id) params.tab_id = tab_id
  if (isPrint) params.print = true

  return (
    axiosOrcatec
      // .get(`/proposal-service-contract-emails/preview-of-document`, { params })
      .get(`/proposals/${proposal_id}/customer-view`, { params })
      .then(response => response.data)
  )
}
// export const getProposalPreviewDataByLink = (token, tabId = '') => {
//   return axiosOrcatec
//     .get(`/preview-of-document-by-link?token=${token}&tab_id=${tabId}&timezone=${moment.tz.guess()}`)
//     .then(response => response.data)
// }

export const getProposalPreviewDataByLink = (token, tabId = '') => {
  // const params = {
  //   token,
  //   tab_id: tabId,
  // }
  return (
    axios
      // .get(
      //   `${
      //     process.env.REACT_APP_API_URL
      //   }preview-of-document-by-link?token=${token}&tab_id=${tabId}&timezone=${moment.tz.guess()}`,
      // )
      .get(
        `${
          process.env.REACT_APP_API_URL
        }public-customer-view?token=${token}&tab_id=${tabId}&timezone=${moment.tz.guess()}`,
      )
      .then(response => response.data)
  )
}

// export const setSignatureByLink = data => {
//   return axiosOrcatec.put('/accept-of-document-by-link', data)
// }
export const setSignatureByLink = data => {
  return axios.put(
    `${process.env.REACT_APP_API_URL}accept-of-document-by-link`,
    data,
  )
}
export const getProposalClientActivity = id => {
  const config = {
    cancelToken: new CancelToken(c => {
      cancelProposalActivityRequest = c
    }),
  }
  return axiosOrcatec
    .get(`/proposals/log/${id}`, config)
    .then(response => response.data)
}

export const getBalanceIDForFastPayment = data => {
  return axiosOrcatec
    .post('/proposals/make-for-fast-payment', data)
    .then(res => res && res.data)
}
export const getTagsStatistics = (params, route) => {
  return axiosOrcatec
    .get(`/${route}/tags/statistics`, { params })
    .then(res => res && res.data)
}

export const getProposalPdf = (proposalId, tabId /* token */) => {
  return axiosOrcatec
    .get(
      `/proposals/${proposalId}/pdf?tabId=${tabId}&print=true&timezone=${moment.tz.guess()}`,
      {
        responseType: 'blob',
      },
    )
    .then(res => res && res.data)
}
export const getPdfOnCustomeView = (proposalId, tabId, token) => {
  return axios
    .get(
      `${
        process.env.REACT_APP_API_URL
      }preview-of-document-by-link/print?token=${token}&tabId=${tabId}&timezone=${moment.tz.guess()}`,
      {
        responseType: 'blob',
      },
    )
    .then(res => res && res.data)
}
