import { useState, useEffect, useMemo } from 'react'
import { Tooltip, Modal, Empty, Divider, Spin, Collapse, Image } from 'antd'
import { isMessagingSubmitted } from 'store/Orcatec/selectors/messaging'
import moment from 'moment-timezone'
import { openNotificationWithIcon } from 'helpers/notifications/openNotificationWithIcon'
import {
  getAllEmailNotificationHistory,
  getAllSmsNotificationHistory,
} from 'api/NotificationModal'
import {
  EmailHistoryIcon,
  ScheduleSendIcon,
} from 'containers/MainContent/Orcatec/components/Icons/CommonIcons'
import { Sms, Email } from '@material-ui/icons'
import { useAppSelector } from 'store/Orcatec/hooks'
import { displayTime, parseItem } from './utils'

import { parsePhoneNumber } from 'features/Messaging/utils'
import {
  Wrapper,
  ModalContentWrapper,
  ModalContentWrapperInner,
  DeviderWrapper,
  HeaderWrapper,
  IconsWrapper,
  HistoryWrapper,
} from './AppointmentNotificationHistory.style'
import { Job } from 'types/Jobs'
import { IAppointment } from '../../../../../../../types/Appointment'
import MainButton from 'containers/MainContent/Orcatec/components/buttons/MainButton'
import { NotificationNumType } from 'features/Notification/types'
import { InfoPlate } from 'components/UIKit'

const { Panel } = Collapse

interface IProps {
  appointment: IAppointment | Job
  showModal?: boolean
}

type ExpandIconPosition = 'start' | 'end'
enum TypeMessage {
  SMS = 'sms',
  EMAIL = 'email',
}
export interface IPropsHistoryItem {
  body: string
  from: string
  recipients:
    | { clients: string[]; technicians: string[]; other: string[] }
    | string
  subject: string
  type: 1 | 3 | 6 | 7 | 4 | 2
  formattedDate?: string
  formattedDateCreate?: string
  created_at?: string
  send_at?: string
  is_sent?: boolean
  created_by: string
  media: string[]
}

const notificationTipeTitle = (type: number) => {
  let msg = 'Canceled Notification'

  if (type === NotificationNumType.APPOINTMENT_REMINDER)
    msg = 'Reminder Notification'
  if (type === NotificationNumType.FOREMAN_LOG) msg = 'Log Sheets Notification'
  if (type === NotificationNumType.IN_ROUTE) msg = 'En Route Notification'
  if (
    [
      NotificationNumType.UPCOMING_APPOINTMENT,
      NotificationNumType.UPCOMING_VISIT,
    ].includes(type)
  )
    msg = 'Upcoming Notification'
  if (type === NotificationNumType.REQUEST_FOR_REVIEW)
    msg = 'Request For Review'
  return msg
}

export const HistoryBody = ({
  body,
  subject,
  media = [],
}: IPropsHistoryItem) => {
  return (
    <HistoryWrapper>
      <div style={{ padding: '0 24px' }}>
        {subject && (
          <p className='history-item'>
            <strong>Subject:</strong>
            <p>{subject}</p>
          </p>
        )}
        {body && (
          <p className='history-item'>
            <strong>Message:</strong>
            <p
              style={{ width: 'calc(100% - 70px)', whiteSpace: 'pre-wrap' }}
              dangerouslySetInnerHTML={{ __html: body }}
            />
          </p>
        )}

        {!!media?.length && (
          <p className='history-item'>
            <strong>Media:</strong>
            <Image
              preview={{
                src: media?.[0],
              }}
              src={media?.[0]}
              style={{ width: '100px', height: '140px' }}
            />
          </p>
        )}
      </div>
    </HistoryWrapper>
  )
}

export const HistoryHeader = ({
  type,
  formattedDate,
  recipients,
  is_sent,
  formattedDateCreate,
  created_by,
}: IPropsHistoryItem) => {
  const isSend = is_sent ? 'Sent by:' : 'Scheduled by:'

  return (
    <HeaderWrapper>
      <p className='history-item'>
        <p>
          <strong>{`${notificationTipeTitle(type)} `}</strong>
        </p>{' '}
        <p>
          <strong> {isSend}</strong>

          <span>{`${created_by} at ${
            !is_sent ? formattedDateCreate : formattedDate
          }`}</span>
        </p>
        {!is_sent && (
          <p>
            <strong>Will be sent at: </strong>
            <span>{`${formattedDate}`}</span>
          </p>
        )}
      </p>
      <p className='history-item'>
        <strong>Sent to:</strong>
        {recipients}
      </p>
    </HeaderWrapper>
  )
}

const AppointmentNotificationHistory = ({ appointment, showModal }: IProps) => {
  const isMessaging = useAppSelector(isMessagingSubmitted)
  const timezone = useAppSelector(
    s => s.orcatec?.company?.timezone || moment.tz.guess(),
  )
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [emailsNotifications, setEmailsNotifications] = useState<
    IPropsHistoryItem[]
  >([])
  const [smsNotifications, setSmsNotifications] = useState<IPropsHistoryItem[]>(
    [],
  )
  const [loading, setLoading] = useState(false)

  const dateFormat = 'YYYYMMDDhhmmss'
  const expandIconPosition: ExpandIconPosition = 'start'
  const formattedDate = (send_at: string) => {
    return moment
      .utc(send_at)
      .tz(timezone)
      .format('MM/DD/YYYY hh:mm A ')
  }

  const handleOpenModal = () => {
    setIsModalOpen(true)
  }

  const handleOk = () => {
    setIsModalOpen(false)
  }

  const fetchAllNotificationHistory = async () => {
    setLoading(true)
    try {
      if (isMessaging) {
        const resSms = await getAllSmsNotificationHistory(appointment.id)
        setSmsNotifications(resSms)
      }
      const resEmail = await getAllEmailNotificationHistory(appointment.id)
      setEmailsNotifications(resEmail)
    } catch (error) {
      const message = 'Error'
      openNotificationWithIcon('error', { message })
    } finally {
      setLoading(false)
    }
  }

  const memoizedHistoryList = useMemo(() => {
    const sms = smsNotifications?.map(s => ({
      ...s,
      from: parsePhoneNumber(s.from),
      formattedDate: formattedDate(s.send_at),
      formattedDateCreate: formattedDate(s.created_at),
      typeMessage: TypeMessage.SMS,
      recipients: [
        ...s?.recipients?.clients?.map(parsePhoneNumber),
        ...s?.recipients?.other?.map(parsePhoneNumber),
        ...s?.recipients?.technicians?.map(parsePhoneNumber),
      ].join(', '),
    }))
    const email = emailsNotifications?.map(s => ({
      ...s,
      from: parseItem(s.from),
      formattedDate: formattedDate(s.send_at),
      formattedDateCreate: formattedDate(s.created_at),
      typeMessage: TypeMessage.EMAIL,
      recipients: [
        ...s?.recipients?.clients?.map(parseItem),
        ...s?.recipients?.other?.map(parseItem),
        ...s?.recipients?.technicians?.map(parseItem),
      ].join(', '),
    }))
    return [...email, ...sms]?.sort(
      (a, b) =>
        +moment(b.send_at).format(dateFormat) -
        +moment(a.send_at).format(dateFormat),
    )
  }, [emailsNotifications, smsNotifications])

  useEffect(() => {
    if (isModalOpen) {
      fetchAllNotificationHistory()
    }
  }, [isModalOpen, appointment])

  useEffect(() => {
    if (showModal !== undefined) setIsModalOpen(showModal)
  }, [showModal])

  return (
    <Wrapper>
      {showModal === undefined && (
        <Tooltip placement='left' title='Show Notification History'>
          <span onClick={handleOpenModal}>
            <EmailHistoryIcon width={40} height={40} />
          </span>
        </Tooltip>
      )}

      <Modal
        zIndex={9999}
        title='Notification History'
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleOk}
        width={800}
        bodyStyle={{ minHeight: '500px' }}
        destroyOnClose
        footer={
          <MainButton
            style={{ marginLeft: 'auto' }}
            onClick={handleOk}
            title='Ok'
          />
        }
      >
        <ModalContentWrapper>
          {!!appointment?.isGroup && (
            <InfoPlate type='info' size='small' width='auto'>
              This event belongs to a group. For group notification history, go
              to &lsquo;Send Group Notification&lsquo; and click on
              &lsquo;Show&nbsp;History&lsquo;.
            </InfoPlate>
          )}

          <Spin className='notification_spin' spinning={loading}>
            {memoizedHistoryList?.length ? (
              <ModalContentWrapperInner>
                {memoizedHistoryList?.map((item, idx) => (
                  <>
                    <Divider>
                      <DeviderWrapper>
                        <p>{displayTime(item.send_at, timezone)}</p>
                      </DeviderWrapper>
                    </Divider>
                    <Collapse expandIconPosition={expandIconPosition}>
                      <Panel
                        header={<HistoryHeader {...item} />}
                        key='1'
                        extra={
                          <IconsWrapper>
                            <span>
                              {item?.typeMessage === TypeMessage.SMS ? (
                                <Sms />
                              ) : (
                                <Email />
                              )}
                            </span>{' '}
                            {!item?.is_sent && (
                              <Tooltip
                                zIndex={999999999}
                                title={`Notification will be sent at: ${item?.formattedDate} `}
                              >
                                <span>
                                  <ScheduleSendIcon
                                    color={'#4c53ef'}
                                    width={22}
                                    height={22}
                                  />
                                </span>
                              </Tooltip>
                            )}
                          </IconsWrapper>
                        }
                      >
                        <HistoryBody key={idx} {...item} />
                      </Panel>
                    </Collapse>
                  </>
                ))}
              </ModalContentWrapperInner>
            ) : (
              <Empty description='No history yet' />
            )}
          </Spin>
        </ModalContentWrapper>
      </Modal>
    </Wrapper>
  )
}

export default AppointmentNotificationHistory
