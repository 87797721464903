import { IAppointment, IIndustries } from 'types/Appointment'
import { FC, useEffect } from 'react'
import { useSelector } from 'react-redux'
import AppointmentSources from '../AppointmentSources'
import AppointmentTypes from '../AppointmentTypes'
// import InputField from 'containers/MainContent/Orcatec/components/Input'
// import { default as Select } from 'containers/MainContent/Orcatec/components/Select'
import moment from 'moment-timezone'
// import { KeyboardDatePicker } from '@material-ui/pickers'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import Notes from '../../../../components/Notes'
// import { onCreateYearsPeriods } from '../../helpers/helpers'
import CustomTimePicker from 'containers/MainContent/Orcatec/components/CustomTimePicker'
// import { Autocomplete } from '@material-ui/lab'

import useTechnicians from 'hooks/useTechnicians'
import { useAppSelector } from 'store/Orcatec/hooks'
import { selectDispatchSettings } from 'features/Dispatch/dispatchSlice'
import { AssignToOption } from 'hooks/useEventAssignToOptions'
import { AppointmentTimezone } from '../AppointmentTimezone/AppointmentTimezone'
import { IEventSettings } from 'features/Settings/Dispatch/components/EventsTab/EventsTab'
import './styles.scss'
import { selectUserPermissionsByName } from 'features/Settings/UsersAndGroups/permissionSlice'
import {
  ModuleName,
  TypeSourcePermissions,
} from 'features/Settings/UsersAndGroups'
import styled from 'styled-components'
import {
  Select,
  TextArea,
  DatePicker as OrcatecDatePicker,
} from 'components/UIKit'
import DatePicker from 'react-multi-date-picker'
import Toolbar from 'react-multi-date-picker/plugins/toolbar'
import DatePanel from 'react-multi-date-picker/plugins/date_panel'
import { DATE_FORMAT } from 'constants/dateFormats'
import { CalendarOutlined } from '@ant-design/icons'
// import { selectCompany } from 'store/Orcatec/selectors/company'
// import { Companies } from 'types/Company'
import Recurrence from 'features/Visits/components/Recurring/components/Recurrence'
import { RecurringOptions as IRecurringOptions } from 'features/Visits/components/Recurring/RecurringModal'

const transformData = data => {
  const groupedData = data.reduce((acc, item) => {
    // If the source does not exist in the accumulator, add it
    if (!acc[item.source]) {
      acc[item.source] = []
    }
    // Push the current item into the respective source group
    acc[item.source].push({
      label: item.name,
      value: item.id,
      key: item.name + item.id,
    })
    return acc
  }, {})

  // Transform the grouped data into the desired structure
  return Object.keys(groupedData).map(key => ({
    label: key,
    options: groupedData[key],
  }))
}

interface Props {
  appointment: IAppointment
  assignToOptions: AssignToOption[]
  eventSettings: IEventSettings
  industries: IIndustries[]
  recurringOptions: IRecurringOptions | null
  onOpenRecurringOptions: () => void
  isContact?: boolean
}

export const Appointment: FC<Props> = ({
  appointment,
  assignToOptions,
  onChange,
  error,
  industries,
  industrieId,
  disabledTime,
  setEdited,
  setTech,
  setAppointmentType,
  setDefaultSourceId,
  setEditedFromParent,
  setNotesEdit,
  eventSettings,
  onTooltipClose,
  isRecurringTooltipVisible,
  recurringOptions,
  isContact,
  onOpenRecurringOptions,
}) => {
  // const { end, start } = onCreateYearsPeriods(5)

  const canEditTypeSource = useAppSelector(
    selectUserPermissionsByName(
      ModuleName.TYPE_SOURCE,
      TypeSourcePermissions.TYPE_SOURCE_CAN_CREATE,
    ),
  )

  // const company = useAppSelector(selectCompany)
  const matrixLog = useAppSelector(state => state.orcatec.dispatchSlice)
  const dispatchSettings = useSelector(selectDispatchSettings)

  const { technicians } = useTechnicians()

  const handleChangeStartDate = (
    date: MaterialUiPickersDate,
    value?: string | null | undefined,
  ) => {
    onChange({
      target: {
        name: 'start_date',
        value: moment(value).format('YYYY-MM-DD'),
      },
    })
  }

  const handleDateChange = (_, options) => {
    onChange({
      target: {
        name: 'date',
        value: appointment.id
          ? options.validatedValue?.[0] || null
          : options.validatedValue,
      },
    })
  }

  /* const handleChangeDate = (
    _: MaterialUiPickersDate,
    value?: string | null | undefined,
  ) => {
    onChange({
      target: {
        name: 'date',
        value: value ? moment(value).format('YYYY-MM-DD') : null,
      },
    })
  } */

  const handleChangeTime = (time: moment.Moment | null, type: string) => {
    onChange({
      target: {
        name: type,
        value: time,
      },
    })
  }

  useEffect(() => {
    setTech?.(technicians)
  }, [technicians])

  /* const handleChangeAssignTo = (
    e: unknown,
    value: string | number | (string | number)[],
  ) => {
    onChange({
      target: {
        name: 'column_template_id',
        value,
      },
    })
  } */

  const handleChangeAssignTo = (_, value) => {
    onChange({
      target: {
        name: 'column_template_id',
        value,
      },
    })
  }

  /*  const renderTags = (value: (string | number)[]) => {
    return (
      <p style={{ paddingLeft: 10, color: '#000' }}>
        {assignToOptions.find(option => option.id === value[0])?.name}
        <span style={{ fontSize: 12 }}>
          {value?.length > 1 ? ` + ${value.length - 1} selected` : ''}
        </span>
        {'\xa0\xa0'}
      </p>
    )
  } */

  if (!assignToOptions?.length) return <p>...loading</p>

  return (
    <div className='appointment'>
      <p className='appt-property__title'> Appointment Info</p>
      <div className='appointment-info'>
        <div>
          <Label>Appointment source</Label>
          <AppointmentSources
            withoutLabel
            name='appointment_source_id'
            value={appointment?.appointment_source_id}
            onChange={onChange}
            error={error}
            required={false}
            setEdited={setEdited}
            setEditedFromParent={setEditedFromParent}
            setDefaultSourceId={setDefaultSourceId}
            appointmentId={appointment?.id}
            disableCRUD={!canEditTypeSource}
          />
        </div>

        <div>
          <Label>Appointment type</Label>
          <AppointmentTypes
            withoutLabel
            name='appointment_type_id'
            value={appointment?.appointment_type_id}
            onChange={onChange}
            error={error}
            isLead={appointment?.is_lead}
            required={false}
            setEdited={setEdited}
            setEditedFromParent={setEditedFromParent}
            setAppointmentType={setAppointmentType}
            appointmentId={appointment?.id}
            disableCRUD={!canEditTypeSource}
            cropped
          />
        </div>

        {industries?.length > 1 && (
          <Select
            label='Industry'
            name='industry_id'
            value={appointment?.industry_id || industrieId}
            onChange={e => onChange(e)}
            options={industries.map(industry => ({
              label: industry.name,
              value: industry.id,
            }))}
            required
            error={error?.industry_id}
            data-testid='industry'
          />
        )}

        <Select
          options={[
            ...technicians,
            { id: 0, name: 'No Preferred User', disabled: false },
          ].map(worker => ({
            label: worker.name,
            value: worker.id,
          }))}
          label='Preferred User'
          value={appointment.preferred_technician_id || ''}
          name='preferred_technician_id'
          onChange={e => onChange(e)}
          placeholder='Select Preferred User'
          // noValue
          // hideDisabled
          error={error?.preferred_technician_id}
          className='select-appt'
          data-testid='preferred_technician'
        />

        {/*  <Select
          options={[
            ...technicians,
            { id: 0, name: 'No Preferred User', disabled: false },
          ]}
          label='Preferred User'
          value={appointment?.preferred_technician_id || 0}
          name='preferred_technician_id'
          onChange={onChange}
          // noValue
          hideDisabled
          error={error}
          helperText={error?.preferred_technician_id}
          className='select-appt'
        /> */}
      </div>

      {eventSettings?.display_timezone && (
        <div>
          <AppointmentTimezone
            onChange={onChange}
            value={appointment.timezone || eventSettings.timezone}
            defaultValue={eventSettings.timezone}
          />
        </div>
      )}

      <div className='appointment-date' style={{ marginBottom: 25 }}>
        {appointment?.is_recurring ? (
          <OrcatecDatePicker
            value={
              appointment?.start_date ? moment(appointment.start_date) : null
            }
            onChange={handleChangeStartDate}
            placeholder='Select date start'
            label='Start Date'
            allowClear={false}
            required
          />
        ) : (
          /*  <>
            {company.id === Companies.WestCost ? (
              <Date error={!!error?.date}>
                <Label>{`Appointment date`}</Label>
                <KeyboardDatePicker
                  clearable
                  autoOk
                  placeholder='Select date'
                  value={
                    typeof appointment.date === 'string'
                      ? moment(appointment.date)
                      : null
                  }
                  // label='Appointment date'
                  inputVariant='outlined'
                  format='MM/DD/YYYY'
                  size='small'
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={!!error?.date}
                  helperText={error?.date}
                  onChange={handleChangeDate}
                  required={!!appointment.assign_to_matrix}
                  cancelLabel=''
                  okLabel=''
                  minDate={start}
                  maxDate={end}
                  variant='inline'
                />
              </Date>
            ) : ( */
          <Date error={!!error?.date}>
            <Label>Appointment date</Label>
            <DatePicker
              multiple={!appointment?.id}
              format={DATE_FORMAT}
              value={
                Array.isArray(appointment.date)
                  ? appointment.date
                  : moment(appointment.date).format(DATE_FORMAT)
              }
              onChange={handleDateChange}
              plugins={[
                <DatePanel key={'panel'} />,
                <Toolbar
                  key='toolbar'
                  position='bottom'
                  names={{ today: '', deselect: '', close: 'OK' }}
                />,
              ]}
              inputClass='styled-datepicker'
              placeholder='Select date'
            />

            <CalendarOutlined />

            {error?.date ? <ErrorText>{error.date}</ErrorText> : ''}
          </Date>
          /*  )}
          </> */
        )}

        {eventSettings?.show_promise_time && (
          <div className='time-picker_wrapper'>
            <CustomTimePicker
              title='Promised arrival time:'
              name='time_start'
              onChange={(value, valueNum, name) =>
                handleChangeTime(value ? moment(value, 'HH:mm ') : null, name)
              }
              value={
                appointment?.time_start
                  ? moment(appointment?.time_start)?.format('HH:mm')
                  : undefined
              }
              endWorkTime={23}
              startWorkTime={0}
              error={error?.time_start}
              required={!!appointment.assign_to_matrix}
              placeholder='Select time'
              withMinute={true}
              showTimeFrom={matrixLog.time_start}
              step={eventSettings?.promised_time_increment}
              short
              style={{ width: '130px', height: 30 }}
            />

            <CustomTimePicker
              name='time_end'
              onChange={(value, valueNum, name) =>
                handleChangeTime(value ? moment(value, 'HH:mm ') : null, name)
              }
              value={
                appointment?.time_end
                  ? moment(appointment?.time_end)?.format('HH:mm')
                  : undefined
              }
              endWorkTime={23}
              startWorkTime={0}
              error={error?.time_end}
              required={!!appointment.assign_to_matrix}
              placeholder='Select time'
              withMinute={true}
              showTimeFrom={matrixLog.time_start}
              step={eventSettings?.promised_time_increment}
              short
              style={{ width: '130px', height: 30 }}
            />
          </div>
        )}
      </div>

      {!appointment.id && !isContact && (
        <div style={{ marginTop: '-20px' }}>
          <Recurrence
            value={appointment.is_recurring}
            isTooltipVisible={isRecurringTooltipVisible}
            recurringOptions={recurringOptions}
            onChange={value =>
              onChange({
                target: {
                  name: 'is_recurring',
                  value,
                },
              })
            }
            onOpen={onOpenRecurringOptions}
            onTooltipClose={onTooltipClose}
          />
        </div>
      )}

      <div className='appointment-date'>
        <Select
          required
          label='Assigned to'
          name='column_template_id'
          mode={!appointment.id ? 'multiple' : undefined}
          value={appointment?.column_template_id}
          options={transformData(assignToOptions)}
          onChange={handleChangeAssignTo}
          listItemHeight={32}
          error={error?.column_template_id}
          data-testid='assigned_to'
        />
        <div className='time-picker_wrapper'>
          {((Array.isArray(appointment?.column_template_id) &&
            appointment.column_template_id.some(
              id => typeof id === 'number',
            )) ||
            ((!!appointment.id || appointment.is_recurring) &&
              typeof appointment.column_template_id === 'number')) && (
            <>
              {' '}
              <CustomTimePicker
                name='matrix_time_start'
                title='Dispatch time:'
                onChange={(value, valueNum, name) =>
                  handleChangeTime(value ? moment(value, 'HH:mm ') : null, name)
                }
                value={moment(appointment.matrix_time_start).format('HH:mm')}
                endWorkTime={dispatchSettings.end_hour - 1}
                startWorkTime={dispatchSettings.start_hour}
                error={error?.matrix_time_start}
                required={!!appointment.assign_to_matrix}
                disabledTime={disabledTime?.matrixTimeStart}
                placeholder='Select time'
                showTimeFrom={dispatchSettings.start_hour}
                short
                style={{ width: '130px', height: 30 }}
              />
              <CustomTimePicker
                name='matrix_time_end'
                onChange={(value, valueNum, name) =>
                  handleChangeTime(value ? moment(value, 'HH:mm ') : null, name)
                }
                value={
                  moment(appointment.matrix_time_end).hour() === 0
                    ? '24:00'
                    : moment(appointment.matrix_time_end).format('HH:mm')
                }
                endWorkTime={dispatchSettings.end_hour}
                startWorkTime={dispatchSettings.start_hour}
                error={error?.matrix_time_end}
                required={!!appointment.assign_to_matrix}
                // disabledTime={disabledTime?.matrixTimeEnd}
                disabledTime={[moment(appointment.matrix_time_start).hour()]}
                placeholder='Select time'
                showTimeFrom={dispatchSettings.start_hour}
                short
                style={{ width: '130px', height: 30 }}
              />
            </>
          )}
        </div>
      </div>

      {/*  {appointment?.is_recurring && (
        <RecurringOptions
          event={appointment}
          onChange={onChange}
          error={error}
        />
      )} */}
      {appointment?.id ? (
        <Notes
          label='Appointment Notes'
          setEdited={setNotesEdit}
          route={`/appointments/${appointment.id}/notes`}
        />
      ) : (
        // <InputField
        //   name='notes'
        //   label={'Appointment notes'}
        //   value={appointment?.notes}
        //   onChange={onChange}
        //   multiline
        //   maxRows={5}
        //   inputProps={{ maxLength: 3000 }}
        //   className='appointment-notes select-appt'
        // />
        <TextArea
          name='notes'
          value={appointment?.notes}
          onChange={onChange}
          label={'Appointment notes'}
          multiline
        />
      )}
    </div>
  )
}

export const Label = styled.p`
  font-size: 12px;
  color: #808080;
  line-height: 1.5;
  margin-bottom: 3px;
`

export const Date = styled.div<{ error?: boolean }>`
  position: relative;

  & > span {
    position: absolute;
    right: 5px;
    top: 28px;
    font-size: 18px;
    pointer-events: none;
    color: #0000008a;
  }

  .rmdp-container {
    width: 100%;
  }

  .styled-datepicker {
    width: 100%;
    border: 1px solid;
    border-color: ${props => (props.error ? '#f12832' : '#d9d9d9')};
    border-radius: 2px;
    height: 32px;
    padding: 4px 25px 4px 11px;
    cursor: pointer;
  }

  .rmdp-toolbar {
    & > div {
      visibility: hidden;

      &:nth-child(3) {
        visibility: visible;
      }
    }
  }

  & > .MuiFormControl-root {
    width: 100%;
  }
`

const ErrorText = styled.p`
  font-size: 12px;
  color: #f12832;
`
