import styled from 'styled-components'

interface Props {
  isCompactView: boolean
  minimizeView: boolean
}

export const AvatarWrapper = styled.div<Props>`
  display: flex;
  /* minimizeView */
  /* flex-direction: column; */
  flex-direction: ${({ minimizeView }) => (minimizeView ? 'row' : 'column')};
  align-items: center;
  height: ${({ isCompactView }) => (isCompactView ? 'auto' : '100%')};
  justify-content: space-between;
  font-size: ${({ isCompactView }) => (isCompactView ? '12px' : '14px')};
  position: relative;

  img {
    width: 55px;
    position: absolute;
    top: -15px;
    transform: rotate(14deg) scaleX(-1);
    right: 30px;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    img {
      right: -10px;
    }
  }
`
