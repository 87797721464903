import { AppStateType } from 'store'
import { AppointmentStatusID } from 'types/Appointment'
import { Job } from 'types/Jobs'
import {
  fetchJobsStarted,
  resetRelatedJobs,
  setAllJobsToComplete,
  setRelatedJobs,
} from '../actions/jobs'

type InitialState = {
  relatedJobs: Job[]
  loading: boolean
  pagination: {
    current_page: number
    per_page: number
    total: number
  }
}

const initialState: InitialState = {
  relatedJobs: [],
  loading: false,
  pagination: {
    current_page: 1,
    per_page: 10,
    total: 0,
  },
}

type ActionTypes =
  | ReturnType<typeof fetchJobsStarted>
  | ReturnType<typeof setRelatedJobs>
  | ReturnType<typeof setAllJobsToComplete>
  | ReturnType<typeof resetRelatedJobs>

export default (state = initialState, action: ActionTypes) => {
  switch (action.type) {
    case 'RESET_RELATED_JOBS':
      return initialState
    case 'FETCH_RELATED_JOBS':
      return {
        ...state,
        loading: true,
      }
    case 'SET_RELATED_JOBS':
      return {
        ...state,
        relatedJobs: action.payload.data,
        pagination: {
          current_page: action.payload.meta.current_page,
          per_page: action.payload.meta.per_page,
          total: action.payload.meta.total,
        },
        loading: false,
      }
    case 'SET_ALL_JOBS_TO_COMPLETE': {
      const updatedRelatedJobs = state.relatedJobs?.map(job => ({
        ...job,
        status:
          !action.isWorkCompleted ||
          job.status === AppointmentStatusID.Cancelled
            ? job.status
            : AppointmentStatusID.Completed,
      }))
      return {
        ...state,
        relatedJobs: updatedRelatedJobs,
      }
    }
    default:
      return state
  }
}

export const selectRelatedJobs = (state: AppStateType): InitialState =>
  state.orcatec.jobs

/* export const selectFilteredJobs = (currentJobId: number) =>
  createSelector(selectRelatedJobs, ({ relatedJobs }) =>
    relatedJobs.filter(job => job.id !== currentJobId),
  ) */
