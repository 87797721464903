export const appointmentStatusFilter = [
  {
    text: 'Active',
    value: '1',
  },
  {
    text: 'En Route',
    value: '5',
  },
  {
    text: 'In Progress',
    value: '2',
  },
  {
    text: 'Completed',
    value: '3',
  },
  {
    text: 'Cancelled',
    value: '4',
  },
]

export const categoryFilter = [
  {
    text: 'Simple',
    value: 'simple',
  },
  // {
  //   text: 'Recurring Parent',
  //   value: 'recurring_parent',
  // },
  {
    text: 'Recurring event',
    value: 'recurring_child',
  },
]

export const menuItems = [
  {
    id: 0,
    title: 'Appointments List',
  },
  {
    id: 1,
    title: 'Time-off List',
  },
]
