import { TimeOff } from 'api/TimeOff'
import { MatrixAddressType } from 'containers/MainContent/Orcatec/Settings/components/calendar/CalendarSettings'
import { AppDispatch, AppStateType } from 'store'
import { ITechnician } from 'types/Appointment'
import { DispatchQueue } from './Queue/types'
import { Error } from 'types/Error'
import { AxiosError } from 'axios'

export interface DispatchEvent {
  appointment_type_id: number | null
  appointment_source_id: number | null
  background_color: string | null
  created_at: string
  event_type?: string
  date: string | null
  id: number | string
  industry_id: number
  column_template_id: number | null
  current_start_time: number | null
  current_end_time: number | null
  matrix_time_start: string | null
  matrix_time_end: string | null
  parent_id?: number | null
  preferred_technician_id: number
  status: number
  time_start: string | null
  time_end: string | null
  queue_id: number | null
  updated_at: string
  count_public_links: number
}
export interface Column {
  background_color: string | null
  column_template_id: number
  id: number
  matrix_id?: number
  main_tech_id: number | null
  main_tech_name?: string | null
  sort_order: number
  technicians: ITechnician[]
  title: string
}

export type MatrixLogAPI = {
  columns: ColumnAPI[]
  date: string
  id: number
  timeOffs: TimeOff[]
  time_start: number
  time_end: number
}

export type MatrixLog = {
  columns: Record<string, Column>
  // events: Record<string, DispatchEvent>
  date: string
  id: number
  technicians: ITechnician[]
  time_start: number
  time_end: number
}

export interface ColumnAPI extends ITechnician {
  column_template_id: number
  events: DispatchEvent[]
  technicians: ITechnician[]
}

export interface ColumnFilters {
  techName: string
  showEmptyColumns: boolean
}

export interface SliceState {
  appointments_created: DispatchEvent[]
  currentDate: string
  data: Record<string, MatrixLog>
  events: Record<string, DispatchEvent>
  filters: ColumnFilters
  freeTechs: ITechnician[]
  loading: boolean
  scrollDirection: 'up' | 'down'
  technicians: ITechnician[]
  settings: DispatchSettings
  queues: Record<string, DispatchQueue>
  error: AxiosError | null
}

export enum CalendarSize {
  S = 'S',
  M = 'M',
  L = 'L',
}
export interface DispatchSettings {
  end_hour: number
  matrix_address_type: MatrixAddressType
  start_hour: number
  tooltip_mod: boolean
  event_color_type: EventColorType
  restrict_crud_source_type: boolean
  industry_id?: number
  promised_time_increment?: number
  size: CalendarSize
}

export enum EventColorType {
  'Event type' = 1,
  User,
  Column,
}

export interface ActionType {
  event: DispatchEvent
  date?: string
}

export interface DefaultError {
  message: string
  errors: Error
}
export interface AsyncThunkAPI<T = DefaultError> {
  dispatch: AppDispatch
  state: AppStateType
  rejectValue: T
}
export interface IEventContact {
  id: number
  name: string
  emails: string[]
  phones: string[]
  type: number
}
