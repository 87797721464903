import { Job, JobsListItem } from 'types/Jobs'
import axiosOrcatec from './axiosInstance'
import { apiV2Config } from './Property'
import { ActionScope } from 'types/Appointment'

export const getJob = (id: number) =>
  axiosOrcatec.get<Job>(`/jobs/${id}`).then(({ data }) => data)

export const getRelatedJobs = params =>
  axiosOrcatec
    .get<PaginatedData<Job>>(`/jobs`, { params })
    .then(({ data }) => data)

export interface PaginatedData<T> {
  data: T[]
  meta: {
    current_page: number
    per_page: number
    total: number
  }
}

export const getJobsList = params =>
  axiosOrcatec
    .get<PaginatedData<JobsListItem>>(`/jobs/list`, { params })
    .then(({ data }) => data)

export const getRecurringStatus = params =>
  axiosOrcatec
    .get(`/jobs/has-active-recurring-visits`, { params })
    .then(({ data }) => data)

export const createJob = (data: Job) =>
  axiosOrcatec.post('/jobs', data, apiV2Config).then(({ data }) => data)

export const createRecurringJobChild = (data: { parent_id: number }) => {
  return axiosOrcatec
    .post(
      'jobs/create-recurring-child',
      data,
      // apiV2Config,
    )
    .then(response => response.data)
}

export const createProposalFromJob = (jobId: number) =>
  axiosOrcatec.post(`/jobs/${jobId}/create-proposal`).then(({ data }) => data)

export const updateJob = (id: number, data: Job) =>
  axiosOrcatec.put(`/jobs/${id}`, data, apiV2Config).then(({ data }) => data)

export const deleteJob = (
  id: number,
  params?: {
    action_scope: ActionScope
  },
) => axiosOrcatec.delete(`/jobs/${id}`, { params }).then(({ data }) => data)

export const duplicateJob = (id: number) =>
  axiosOrcatec.get(`/jobs/copy/${id}`).then(({ data }) => data)

export default {
  getJob,
  getJobs: getRelatedJobs,
  getJobsList,
  createJob,
  createProposalFromJob,
  getRecurringStatus,
  updateJob,
  deleteJob,
  duplicateJob,
}
