import { useEffect, useState } from 'react'
import { RecurringOptions } from '../../RecurringModal'
import {
  RecurringInterval,
  RecurringMonthlyType,
  RecurringSelectedDay,
} from 'types/Appointment'
import moment from 'moment-timezone'

export const useRecurringEventQty = (settings: RecurringOptions) => {
  const [eventQty, setEventQty] = useState<number>(0)

  useEffect(() => {
    const calculateRecurringEventsQty = (
      settings: RecurringOptions,
    ): number => {
      const startDate = moment(settings.date)

      if (settings.ending_options.end_type === 2) {
        // End by quantity of events
        return settings.ending_options.value as number
      } else {
        // End by date
        const endDate = moment(settings.ending_options.value as string)
        let eventCount = 0
        const currentDate = startDate.clone()

        if (settings.interval === RecurringInterval.DAY) {
          // For daily intervals, every day matches
          while (currentDate.isSameOrBefore(endDate)) {
            eventCount++
            currentDate.add(settings.repeat_every || 1, 'days')
          }
        } else if (settings.interval === RecurringInterval.WEEK) {
          // For weekly intervals, count occurrences of specified weekdays based on repeat_every
          while (currentDate.isSameOrBefore(endDate)) {
            // Calculate all days of the week specified in 'each' for the current week interval
            settings.each.forEach(dayOfWeek => {
              // Start at the beginning of the interval week and move to the target day of the week
              const dayToCheck = currentDate
                .clone()
                .startOf('week')
                .add(dayOfWeek, 'days')

              // Ensure the day is within the overall date range
              if (
                dayToCheck.isSameOrAfter(startDate) &&
                dayToCheck.isSameOrBefore(endDate)
              ) {
                eventCount++
              }
            })

            // Move to the next 'repeat_every' weeks interval, resetting to the start of that week
            currentDate.add(settings.repeat_every || 1, 'weeks').startOf('week')
          }
        } else if (settings.interval === RecurringInterval.MONTH) {
          // Monthly intervals can be DAILY or WEEKLY
          while (currentDate.isSameOrBefore(endDate)) {
            if (settings.monthly_type === RecurringMonthlyType.DAILY) {
              // DAILY: Check specific days of the month
              // eslint-disable-next-line @typescript-eslint/no-extra-semi
              ;(settings.each as number[]).forEach(dayOfMonth => {
                const dayToCheck = currentDate.clone().date(dayOfMonth)
                if (
                  dayToCheck.isSameOrAfter(startDate) &&
                  dayToCheck.isSameOrBefore(endDate)
                ) {
                  eventCount++
                }
              })
            } else if (settings.monthly_type === RecurringMonthlyType.WEEKLY) {
              // WEEKLY: Check specific occurrences of days in the month
              // eslint-disable-next-line @typescript-eslint/no-extra-semi
              ;(settings.each as RecurringSelectedDay[]).forEach(
                ({ weekNumber, day }) => {
                  const dayToCheck = currentDate
                    .clone()
                    .startOf('month')
                    .add(weekNumber - 1, 'weeks')
                    .day(day)
                  if (
                    dayToCheck.month() === currentDate.month() &&
                    dayToCheck.isSameOrAfter(startDate) &&
                    dayToCheck.isSameOrBefore(endDate)
                  ) {
                    eventCount++
                  }
                },
              )
            }
            currentDate.add(settings.repeat_every || 1, 'months')
          }
        }

        return eventCount
      }
    }

    setEventQty(calculateRecurringEventsQty(settings))
  }, [settings])

  return eventQty
}
