import './ToggleButton.scss'
import { DeleteIcon } from '../../Icons/CommonIcons'
import { SmallPreloader } from '../../Preloader/SmallPreloader'

const defaultAction = () => {}

const ToggleButton = ({
  show,
  toggle = defaultAction,
  type = 'toggle',
  loading = false,
}) =>
  type !== 'delete' ? (
    <div
      role='button'
      className={'custom-toggle-btn' + (show ? ' active' : '')}
      onClick={toggle}
    >
      <i className={show ? 'mdi mdi-chevron-down' : 'mdi mdi-chevron-right'} />
    </div>
  ) : (
    <div
      role='button'
      className={'custom-delete-btn'}
      onClick={loading ? defaultAction : toggle}
    >
      {!loading ? <DeleteIcon /> : <SmallPreloader />}
    </div>
  )

export default ToggleButton
