import { separateDateTimeByStr } from 'helpers/date/separateDateTimeByStr'
import { useDispatch } from 'react-redux'

import Appointment from '../Appointment'
import AppointmentStatusSwitcher from '../AppointmentStatusSwitcher'
import React, { useState } from 'react'
import { Switch, Tooltip } from 'antd'
import { changeAppointmentStatus } from '../../../../../../../api/Appointment'
import { openNotificationWithIcon } from '../../../../../../../helpers/notifications/openNotificationWithIcon'
import moment from 'moment-timezone'
import './styles.scss'
import ChildAppointmentList from '../ChildAppointmentsList'
// import { getModuleStatus } from 'store/Orcatec/reducers/settings/subscription'
import {
  EventKind,
  AppointmentStatusID,
  IAppointment,
  RecurringInterval,
} from 'types/Appointment'
import { Error } from 'types/Error'
import { Job as JobType } from 'types/Jobs'
import Job from 'containers/MainContent/Orcatec/Jobs/components/Job'

import AppointmentToPrint from '../AppointmentToPrint/AppointmentToPrint'
// import AppointmentNotificationHistory from '../AppointmentNotificationHistory/AppointmentNotificationHistory'
import {
  eventStatusUpdated,
  // selectMatrixLog,
} from 'features/Dispatch/dispatchSlice'
import { useAppSelector } from 'store/Orcatec/hooks'
import InputFieldTransformable from 'containers/MainContent/Orcatec/components/UI/InputFieldTransformable'
import { AssignToOption } from 'hooks/useEventAssignToOptions'
import { NotificationModal } from 'features/Notification/modals/NotificationModal/NotificationModal'
import {
  NotificationTemplateType,
  NotificationModalType,
  NotificationTitleType,
} from 'features/Notification/types'
import { getNotificationSettings } from 'api/NotificationModal'
import { IEventSettings } from 'containers/MainContent/Orcatec/Settings/Dispatch/components/EventsTab/EventsTab'
import MainButton from 'containers/MainContent/Orcatec/components/buttons/MainButton'
import { selectModulePermissions } from 'features/Settings/UsersAndGroups/permissionSlice'
import { selectJobDictionary } from 'store/Orcatec/selectors/company'
import { IconEnvelope } from 'containers/MainContent/Orcatec/components/Icons/CommonIcons'
import { RecurringOptions } from 'features/Visits/components/Recurring/RecurringModal'

export interface ITechnicians {
  id: number
  name: string
  title: string
  disable: boolean
}

export interface IIndustries {
  id: number
  name: string
}

export interface IDisableTime {
  matrixTimeStart: number[]
  matrixTimeEnd: number[]
  matrixTime: number[]
}
interface Props {
  assignToOptions: AssignToOption[]
  data: IAppointment | JobType
  onChange: (e: { target: { name: string; value: unknown } }) => void
  onStatusUpdated: () => void
  error: Error
  loading?: boolean
  me: {
    id: number
    full_name: string
  }
  technicians: ITechnicians[]
  industries: IIndustries[]
  disabledTime: IDisableTime[]
  industrieId: number
  setEdited: (value: boolean) => void
  setEditedFromParent: () => void
  onCreateNexRecurringtChild?: () => void
  setAppointmentType: (type: []) => void
  setDefaultSourceId: (id: number) => void
  onNotificationSend?: (groupId?: number | null) => void
  onOpenRecurringOptions: () => void
  eventSettings: IEventSettings
  recurringOptions: RecurringOptions | null
}

export const AppointmentSection = ({
  data,
  onChange,
  error,
  loading,
  me,
  disabledTime,
  industries,
  onStatusUpdated,
  industrieId,
  setEdited,
  setAppointmentType,
  // setTechnician,
  setDefaultSourceId,
  setEditedFromParent,
  // setNotesEdit,
  assignToOptions,
  eventSettings,
  onCreateNexRecurringtChild,
  onNotificationSend,
  recurringOptions,
  onOpenRecurringOptions,
}: Props) => {
  const [isRecurringTooltipVisible, setIsRecurringTooltipVisible] = useState(
    false,
  )
  const [showCancellationModal, setShowCancellationModal] = useState(false)
  const [isAppointmentStatusChanging, setAppointmentStatusChanging] = useState(
    false,
  )
  const [
    showNotificationUpcomingAppointmentModal,
    setShowNotificationUpcomingAppointmentModal,
  ] = useState(false)
  const [
    showNotificationInRouteModal,
    setShowNotificationInRouteModal,
  ] = useState(false)
  const [
    showNotificationCanceledModal,
    setShowNotificationCanceledModal,
  ] = useState(false)
  const [isShownRfRModal, setIsShownRfRModal] = useState(false)

  const [tech, setTech] = useState([])
  /* const isRecurringModuleActive = useSelector(state =>
    getModuleStatus(state, 'recurring_appointments'),
  ) */

  const modulePermissions = useAppSelector(selectModulePermissions())

  const dictionary = useAppSelector(selectJobDictionary)

  const dispatch = useDispatch()

  const isJob = (data: IAppointment | JobType): data is JobType =>
    (data as JobType)?.kind === EventKind.JOB

  const handleChangeAppointmentStatus = (
    appointmentID: number | undefined,
    appointmentStatusID: AppointmentStatusID,
  ) => {
    if (!appointmentID) return

    if (appointmentStatusID !== data?.status) {
      setAppointmentStatusChanging(true)
      const statusData =
        appointmentStatusID === AppointmentStatusID?.Cancelled
          ? {
              status: appointmentStatusID,
              reason: data?.canceled_info?.reason,
            }
          : { status: appointmentStatusID }
      changeAppointmentStatus(appointmentID, statusData)
        .then(() => {
          // if (
          //   !!data.column_template_id &&
          //   matrixLog.data[moment(data.matrix_date).format('MM/DD/YYYY')]
          // ) {
          dispatch(
            eventStatusUpdated({
              eventId: appointmentID,
              status: appointmentStatusID,
            }),
          )
          // }

          onChange({
            target: { name: 'appointment-status', value: appointmentStatusID },
          })
          if (appointmentStatusID === AppointmentStatusID.Cancelled) {
            onChange({
              target: {
                name: 'canceled_info',
                value: {
                  canceled_by: {
                    name: me?.full_name,
                    id: me?.id,
                  },
                  date: new Date(),
                  reason: data?.canceled_info?.reason,
                },
              },
            })
          } else
            onChange({ target: { name: 'status', value: appointmentStatusID } })
        })
        .then(async () => {
          const notificationSettings = await getNotificationSettings(
            data.industry_id,
          )

          if (
            appointmentStatusID === AppointmentStatusID['En Route'] &&
            notificationSettings?.should_display_in_route
          ) {
            return setShowNotificationInRouteModal(true)
          } else if (
            appointmentStatusID === AppointmentStatusID.Active &&
            notificationSettings?.should_display_upcoming
          ) {
            return setShowNotificationUpcomingAppointmentModal(true)
          } else if (
            appointmentStatusID === AppointmentStatusID.Cancelled &&
            notificationSettings?.should_display_canceled
          ) {
            return setShowNotificationCanceledModal(true)
          } else if (
            appointmentStatusID === AppointmentStatusID.Completed &&
            notificationSettings?.should_display_request_for_review_event_completed &&
            modulePermissions?.reviews
          ) {
            return setIsShownRfRModal(true)
          }
        })
        .then(() => {
          onStatusUpdated?.(statusData)
          openNotificationWithIcon('success', {
            message: 'Success',
            description: 'Appointment status change was success',
          })
        })
        .catch(e => {
          openNotificationWithIcon('error', {
            message: `Error: ${e?.response?.status}`,
            description: e?.response?.statusText,
          })
        })
        .finally(() => {
          setAppointmentStatusChanging(false)
          setShowCancellationModal(false)
          setEdited(false)
        })
    }
  }

  const handleChangeCancellationReason = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { value } = e.target

    onChange({
      target: {
        name: 'canceled_info',
        value: {
          ...data?.canceled_info,
          reason: value,
        },
      },
    })
  }

  const onRecurringClicked = () => {
    setIsRecurringTooltipVisible(true)
  }

  /* const handleChangeReccuring = (isReccuring: boolean) => {
    if (!isRecurringModuleActive) {
      return openNotificationWithIcon('info', {
        message: `Module is off`,
        description: `You have no permission to perform this action. Please, choose 'Recurring Events' in Optional Features`,
      })
    }

    onChange({
      target: {
        name: 'is_recurring',
        value: isReccuring,
      },
    })
  }
 */
  // const handleOpenParentAppointment = (id: number) =>
  //   window.open(`/${isJob(data) ? 'jobs' : 'appointment'}/${id}`, '_blank')

  return (
    <div className='appointment-section'>
      {isAppointmentStatusChanging && <div className={'statuses-loader'} />}

      {(!data?.id || (!!data?.id && data?.is_recurring)) && (
        <div className='reccruing-switcher'>
          <Switch
            checked={!!data.is_recurring}
            onChange={onRecurringClicked}
            disabled={!!data.id || !assignToOptions.length}
          />
          <span>Recurring</span>
        </div>
      )}

      {/*  {!!data?.parent_id && (
        <p className='parent-appointment'>
          <p className='recurring-id'>
            {isJob(data) ? dictionary.singular : 'Appointment'} ID:{' '}
            <span>{data.number}</span>
          </p>
          This {isJob(data) ? dictionary.singular : 'appointment'} was created
          from parent recurring{' '}
          {isJob(data) ? dictionary.singular : 'appointment'}{' '}
          <span
            className='link'
            onClick={() => handleOpenParentAppointment(data.parent_id)}
          >
            ID: R{data.parent_id}
          </span>
        </p>
      )} */}

      <div className='appointment-section__body'>
        <div className='appointment-section__main-info'>
          {(!!data.group_id || !!data.parent_id) && (
            <h5>
              {data.group_id ? 'Group' : 'Recurring'}{' '}
              {isJob(data) ? 'visit' : 'appointment'}
            </h5>
          )}

          {isJob(data) ? (
            <Job
              assignToOptions={assignToOptions}
              job={data}
              onChange={onChange}
              error={error}
              setTech={workers => setTech(workers)}
              disabledTime={disabledTime}
              setEditedFromParent={setEditedFromParent}
              eventSettings={eventSettings}
              isRecurringTooltipVisible={isRecurringTooltipVisible}
              recurringOptions={recurringOptions}
              onOpenRecurringOptions={onOpenRecurringOptions}
              onTooltipClose={() => setIsRecurringTooltipVisible(false)}
            />
          ) : (
            <Appointment
              assignToOptions={assignToOptions}
              disabledTime={disabledTime}
              appointment={data}
              onChange={onChange}
              industries={industries}
              error={error}
              industrieId={industrieId}
              allowDropToSchedule
              setEdited={setEdited}
              setTech={workers => {
                // setTechnician?.(workers)
                setTech(workers)
              }}
              setAppointmentType={setAppointmentType}
              setDefaultSourceId={setDefaultSourceId}
              setEditedFromParent={setEditedFromParent}
              eventSettings={eventSettings}
              //
              isRecurringTooltipVisible={isRecurringTooltipVisible}
              recurringOptions={recurringOptions}
              onOpenRecurringOptions={onOpenRecurringOptions}
              onTooltipClose={() => setIsRecurringTooltipVisible(false)}
            />
          )}
        </div>

        {!!data?.id && (
          <div className='appointment-section__main-status'>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <AppointmentStatusSwitcher
                appointment={data}
                cancellationOnChange={handleChangeCancellationReason}
                handleChangeStatus={handleChangeAppointmentStatus}
                showCancellationModal={showCancellationModal}
                setShowCancellationModal={setShowCancellationModal}
                canEdit={true}
              />

              <div style={{ marginLeft: 'auto' }}>
                <Tooltip placement='left' title='Send upcoming notification'>
                  <span
                    onClick={() => {
                      !!onNotificationSend
                        ? onNotificationSend(data?.group_id)
                        : setShowNotificationUpcomingAppointmentModal(true)
                    }}
                    style={{ cursor: 'pointer' }}
                  >
                    <IconEnvelope
                      style={{ fontSize: 40, position: 'relative', top: 3 }}
                    />
                  </span>
                </Tooltip>
              </div>
            </div>

            {showNotificationUpcomingAppointmentModal && (
              <NotificationModal
                title={`Upcoming ${
                  data?.kind === EventKind.JOB
                    ? dictionary.singular
                    : 'Appointment'
                } Notification`}
                templateType={NotificationTemplateType.APPOINTMENT}
                notificationType={NotificationModalType.UPCOMING}
                showModal={showNotificationUpcomingAppointmentModal}
                setShowModal={setShowNotificationUpcomingAppointmentModal}
                appointment={data}
              />
            )}
            {showNotificationInRouteModal && (
              <NotificationModal
                title='En Route Notification'
                templateType={NotificationTemplateType.APPOINTMENT}
                notificationType={NotificationModalType.EN_ROUTE}
                showModal={showNotificationInRouteModal}
                setShowModal={setShowNotificationInRouteModal}
                appointment={data}
              />
            )}
            {showNotificationCanceledModal && (
              <NotificationModal
                title='Canceled Notification'
                templateType={NotificationTemplateType.APPOINTMENT}
                notificationType={NotificationModalType.CANCELED}
                showModal={showNotificationCanceledModal}
                setShowModal={setShowNotificationCanceledModal}
                appointment={data}
              />
            )}

            {isShownRfRModal && (
              <NotificationModal
                title={NotificationTitleType.REQUEST_FOR_REVIEW}
                templateType={NotificationTemplateType.APPOINTMENT}
                notificationType={NotificationModalType.REQUEST_FOR_REVIEW}
                showModal={isShownRfRModal}
                setShowModal={setIsShownRfRModal}
                appointment={data}
                changeStatus={value =>
                  onChange({ target: { name: 'review_status', value } })
                }
                customSubmit
              />
            )}

            {data?.status === 4 && (
              <>
                {data?.canceled_info && (
                  <>
                    <div className='canceled-info'>
                      Cancelled by:{' '}
                      <span>
                        {data.canceled_info?.canceled_by?.name +
                          ' on ' +
                          moment
                            .utc(data?.canceled_info?.date)
                            .local()
                            .format('MM/DD/YYYY') +
                          ' at ' +
                          moment
                            .utc(data?.canceled_info?.date)
                            .local()
                            .format('hh:mma')}
                      </span>
                    </div>
                    <div
                      className='canceled-info'
                      style={{ display: 'flex', gap: 5, alignItems: 'center' }}
                    >
                      Reason:
                      <span>
                        <InputFieldTransformable
                          value={data?.canceled_info?.reason}
                          transform
                          onChange={handleChangeCancellationReason}
                        />
                      </span>
                      {/* Reason: <span>{data?.canceled_info?.reason}</span> */}
                    </div>
                  </>
                )}
              </>
            )}
            {!!data?.created_at && (
              <div className='canceled-info'>
                {data.kind === EventKind.APPOINTMENT
                  ? 'Appointment'
                  : dictionary.singular}{' '}
                created by:{' '}
                <span>
                  {data.username === null
                    ? `Public Appointment`
                    : `${data.username} on ${separateDateTimeByStr(
                        'at',
                        data.created_at,
                      )}`}
                </span>
              </div>
            )}
          </div>
        )}
      </div>

      <div className='appointment-section__body_toPrint'>
        <AppointmentToPrint
          isJob={isJob(data)}
          tech={tech}
          appointment={data}
          jobTitle={dictionary.singular}
          showPromisedTime={eventSettings?.show_promise_time}
        />
      </div>

      {data.id && data.interval === RecurringInterval.MONTH && (
        <MainButton
          title='Create next recurring event'
          onClick={onCreateNexRecurringtChild}
          style={{ margin: '10px 0' }}
          isFetching={loading}
        />
      )}

      {!!data?.children_appointments?.length && (
        <ChildAppointmentList data={data?.children_appointments} />
      )}
    </div>
  )
}
