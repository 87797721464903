import styled from 'styled-components'

export const Wrapper = styled.div<{ isEditView: boolean }>`
  margin: 20px 10px 30px;
  justify-content: center;
  display: grid;
  grid-template-columns:
    minmax(375px, ${props => (props.isEditView ? '1250px' : '850px')})
    300px;
  grid-template-areas: 'main sidebar';
  gap: 24px;
  background-color: #fcfcfd;

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    grid-template-areas:
      'sidebar'
      'main';
    justify-content: initial;
  }
`

export const Content = styled.div`
  grid-area: main;
`
export const SidebarWrapper = styled.div`
  grid-area: sidebar;
`
export const Sidebar = styled.div<{ isSticky: boolean }>`
  position: ${props => (props.isSticky ? 'sticky' : 'initial')};
  top: 10px;

  @media screen and (max-width: 768px) {
    position: initial;
  }
`

export const SidebarContent = styled.div`
  margin-top: 25px;

  @media screen and (max-width: 768px) {
    display: none;
  }
`

export const ContentSwitcher = styled.div`
  background-color: #f3f3f3;
  border-radius: 4px;
  display: flex;
  height: 40px;
  padding: 4px;
`
export const SwitcherButton = styled.p<{ active: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${props => (props.active ? '#000' : '#999999')};
  background-color: ${props => (props.active ? '#fff' : '#initial')};
  cursor: pointer;
  font-weight: 500;
  flex: 0 0 50%;
  border-radius: 4px;
`
