import { useEffect, useState } from 'react'
import JobsAPI from 'api/Job'

export const useRecurringProcess = (
  entityId: number,
  entityKey: 'proposal_id' | 'wo_proposal_id',
) => {
  const [isProcessingRecurring, setIsProcessingRecurring] = useState(false)

  useEffect(() => {
    const getRecurringStatus = async () => {
      const { has_active_recurring_visits } = await JobsAPI.getRecurringStatus({
        [entityKey]: entityId,
      })
      setIsProcessingRecurring(has_active_recurring_visits)
    }

    if (entityId) {
      getRecurringStatus()
    }
  }, [entityId])

  return { isProcessingRecurring }
}
